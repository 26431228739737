import {cn} from '../../../lib/utils';
import {SendIcon} from '../../icons/Send.icon';
import Textarea from 'react-textarea-autosize';
import { RecordContainer } from './RecordContainer';
import { AIType } from '../../../types/chat.types';
import { useEnterSubmit } from '../../../hooks/lib/hooks/use-enter-submit';

export interface ChatPanelProps {
  sendMessage: (value: string) => void;
  input: string;
  setInput: (value: string) => void;
  isOpen: boolean;
  ai_type: AIType
}

export const ChatPanel = ({isOpen, input, setInput, sendMessage, ai_type}: ChatPanelProps) => {
    const { formRef, onKeyDown } = useEnterSubmit()
  return (
    <form
       ref={formRef}
      onSubmit={async (e) => {
        e.preventDefault();
        if (!input?.trim()) {
          return;
        }
        setInput('');
        sendMessage(input);
      }}
      className="flex pr-[18px] items-center justify-between w-full mt-auto shrink-0">
      <Textarea
        tabIndex={0}
        rows={1}
        value={input}
        onChange={(e) => setInput(e.target.value)}
        // Ask a question
        onKeyDown={onKeyDown}
        placeholder="Напишите свой запрос..."
        spellCheck={false}
        className="outline-none pl-[18px] border-none text-[16px] h-full flex-1 py-2 resize-none "
      />
      <div>
        <RecordContainer isChatMicro ai_type={ai_type} />
      </div>
      <button className="bg-main-green h-[44px] my-2  px-[15px] rounded-full flex items-center justify-center text-white gap-2 transition-all">
        <div
          className={cn(
            'transition-all grid delay-300 font-medium text-lg !leading-none overflow-hidden',
            isOpen ? 'grid-cols-[1fr]' : 'delay-0 grid-cols-[0fr]'
          )}>
          <span className="min-w-[0px]">Отправить</span>
        </div>
        <SendIcon />
      </button>
    </form>
  );
};
