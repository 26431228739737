import {create} from "zustand";
import axios from "axios";

interface UserInterface {
  user: null | string,
  setUser: (user: string) => void;
  // login: (username: string, password: string) => Promise<void>;
  // logout: () => void;
}
axios.defaults.baseURL = 'https://expoapi.nimbl.tv/en/api/'
export const useUserStore = create<UserInterface>(set => ({
  user: null,
  setUser(user) {
    set({user});
  },

}))