import {useAudioRecords} from '../../../hooks/useAudioRecords';
import {cn} from '../../../lib/utils';
import {AIType} from '../../../types/chat.types';

export const RecordContainer = ({isChatMicro, ai_type}: {isChatMicro: boolean; ai_type: AIType}) => {
  const {handleRecord, isRecording} = useAudioRecords({ai_type});

  return (
    <div className="flex items-center gap-2 relative">
      <div
        className={cn(
          'pointer-events-none transition-all right-[calc(100%+10px)] absolute ',
          isRecording ? 'opacity-100' : 'opacity-0'
        )}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="173"
          height="28"
          fill="none"
          viewBox="0 0 173 28"
          className="pointer-events-none">
          <g fill="#123354">
            <g id="rect">
              <rect width="5" height="24" y="2" rx="2.5"></rect>
            </g>
            <g id="rect">
              <rect width="5" height="20" x="7" y="4" rx="2.5" />
            </g>
            <g id="rect">
              <rect width="5" height="12" x="14" y="8" rx="2.5" />
            </g>
            <g id="rect">
              <rect width="5" height="6" x="21" y="11" rx="2.5" />
            </g>
            <g id="rect">
              <rect width="5" height="10" x="28" y="9" rx="2.5" />
            </g>
            <g id="rect">
              <rect width="5" height="18" x="35" y="5" rx="2.5" />
            </g>
            <g id="rect">
              <rect width="5" height="14" x="42" y="7" rx="2.5" />
            </g>
            <g id="rect">
              <rect width="5" height="8" x="49" y="10" rx="2.5" />
            </g>
            <g id="rect">
              <rect width="5" height="14" x="56" y="7" rx="2.5" />
            </g>
            <g id="rect">
              <rect width="5" height="18" x="63" y="5" rx="2.5" />
            </g>
            <g id="rect">
              <rect width="5" height="24" x="70" y="2" rx="2.5" />
            </g>
            <g id="rect">
              <rect width="5" height="20" x="77" y="4" rx="2.5" />
            </g>
            <g id="rect">
              <rect width="5" height="14" x="84" y="7" rx="2.5" />
            </g>
            <g id="rect">
              <rect width="5" height="8" x="91" y="10" rx="2.5" />
            </g>
            <g id="rect">
              <rect width="5" height="4" x="98" y="12" rx="2" />
            </g>
            <g id="rect">
              <rect width="5" height="6" x="105" y="11" rx="2.5" />
            </g>
            <g id="rect">
              <rect width="5" height="12" x="112" y="8" rx="2.5" />
            </g>
            <g id="rect">
              <rect width="5" height="18" x="119" y="5" rx="2.5" />
            </g>
            <g id="rect">
              <rect width="5" height="24" x="126" y="2" rx="2.5" />
            </g>
            <g id="rect">
              <rect width="5" height="28" x="133" rx="2.5" />
            </g>
            <g id="rect">
              <rect width="5" height="24" x="140" y="2" rx="2.5" />
            </g>
            <g id="rect">
              <rect width="5" height="16" x="147" y="6" rx="2.5" />
            </g>
            <g id="rect">
              <rect width="5" height="10" x="154" y="9" rx="2.5" />
            </g>
            <g id="rect">
              <rect width="5" height="6" x="161" y="11" rx="2.5" />
            </g>
            <g id="rect">
              <rect width="5" height="14" x="168" y="7" rx="2.5" />
            </g>
          </g>
        </svg>
      </div>
      <div className="w-[44px] h-[44px] cursor-pointer rounded-full p-0 mr-2 ">
        <button
          type={'button'}
          onClick={() => handleRecord()}
          className={cn('relative  w-full h-full rounded-full', isRecording ? 'bg-[#EB5757]' : 'bg-blue-main')}>
          {isRecording ? (
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className='absolute top-1/2 -translate-x-1/2 -translate-y-1/2 left-1/2 transition-all z-10'>
              <rect width="20" height="20" rx="5" fill="white" />
              <path
                d="M7.5 1C6.83696 1 6.20107 1.29176 5.73223 1.81109C5.26339 2.33042 5 3.03479 5 3.76923V10.2308C5 10.9652 5.26339 11.6696 5.73223 12.1889C6.20107 12.7082 6.83696 13 7.5 13C8.16304 13 8.79893 12.7082 9.26777 12.1889C9.73661 11.6696 10 10.9652 10 10.2308V3.76923C10 3.03479 9.73661 2.33042 9.26777 1.81109C8.79893 1.29176 8.16304 1 7.5 1Z"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M14 8V9.83333C14 11.5351 13.3152 13.1672 12.0962 14.3706C10.8772 15.574 9.22391 16.25 7.5 16.25M7.5 16.25C5.77609 16.25 4.12279 15.574 2.90381 14.3706C1.68482 13.1672 1 11.5351 1 9.83333V8M7.5 16.25V19"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="63"
              height="87"
              fill="white"
              viewBox="0 0 63 87"
              className="absolute top-1/2 -translate-x-1/2 -translate-y-1/2 left-1/2 transition-all z-10 w-[15px]">
              <path
                strokeWidth="4"
                d="M29.204 73.134v-1.757l-1.743-.226C13.008 69.28 2 57.543 2 43.5v-6.797c0-1.059.92-2.078 2.295-2.078s2.296 1.02 2.296 2.078V43.5c0 13.215 11.25 23.75 24.909 23.75 13.66 0 24.91-10.535 24.91-23.75v-6.797c0-1.059.92-2.078 2.295-2.078S61 35.645 61 36.703V43.5c0 14.043-11.008 25.78-25.461 27.65l-1.744.227v9.467h10.591c1.375 0 2.296 1.02 2.296 2.078 0 1.059-.92 2.078-2.296 2.078H18.614c-1.375 0-2.296-1.02-2.296-2.078 0-1.059.92-2.078 2.296-2.078h10.59v-7.71ZM16.319 16.312C16.318 8.508 23.02 2 31.5 2s15.182 6.508 15.182 14.313V43.5c0 7.805-6.702 14.313-15.182 14.313S16.318 51.304 16.318 43.5V16.312Z"
              />
            </svg>
          )}
        </button>
      </div>
    </div>
  );
};
