import React, {useState} from "react";
import EmployeesTable, {VacanciesTable} from "../../components/EmployeesTable/EmployeesTable";
import {Select, SelectContent, SelectItem, SelectTrigger, SelectValue} from "../../components/ui/select";
import HrRecords from "../../components/HrRecords/HrRecords";
import {ChatAssistAi} from "../../components/ChatAssistAi";
import {AIType} from "../../types/chat.types";
import {managingHRItems, managingInventoryItems} from "../../components/DataTable/mockdata";
import ModalWindow from "../../components/ModalWindow/ModalWindow";
import GeneralTable from "../../components/InventoryTables/GeneralTable";

const PersonnelRecords = () => {
  const [tableType, setTableType] = useState('')
  const [showModal, setShowModal] = useState(false)

  const handleModal = (value?: string) => {
    setShowModal(!showModal)
    if (value) {
      setTableType(value)
    }
    console.log(value)
  }
  return (
    <div className={'relative flex flex-col gap-8'}>
      {showModal && (
        <ModalWindow show={showModal} handleModal={handleModal} type={tableType} modalType={"Кадровое делопроизводство"}>
          {tableType === "ГПХ" ? (
            <EmployeesTable type={'Сотрудники на ГПХ'}/>
          ) : (
            <VacanciesTable type={tableType}/>
          )}
        </ModalWindow>
      )}
      <div className={'flex gap-6 flex-row-reverse flex-wrap transition-all'}>
        <ChatAssistAi module={AIType.MANAGEMENT}/>
        {/*<HrRecords/>*/}
        <div className='flex-1 flex flex-col justify-between bg-white border-[1px] border-[#E0E0E0] rounded-[15px] pb-2'>
          <h3 className="font-medium text-[20px] text-main-dark-gray px-10 py-6">Кадровое делопроизводство</h3>
          <div className={'flex flex-col gap-1 px-3'}>
            {managingHRItems.map((item) => (
              <div key={item.id}
                   onClick={item.title !== "Количество сотрудников" ? () => handleModal(item.title) : undefined}
                   className={'flex justify-between items-center px-9 py-4 border-[1px] border-[#E0E0E0] rounded-[12px] cursor-pointer'}>
                <h2 className={'font-medium text-main-dark-gray text-[16px]'}>{item.title}</h2>
                <p className={'text-[#737984]'}>{item.quantity}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div>
        <EmployeesTable />
      </div>
    </div>
  );
};
export default PersonnelRecords;
