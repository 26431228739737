import {ReactNode} from "react";
import {IoClose} from "react-icons/io5";

interface ModalWindowProps {
  show: boolean;
  handleModal: () => void;
  type: string;
  children: ReactNode;
  modalType:string;
}

const ModalWindow = ({show, handleModal, type, children, modalType}: ModalWindowProps) => {
  if (!show) return null;

  return (
    <div
      className="absolute top-[-34px] left-[-34px] w-[calc(100%+68px)] h-[calc(100%+68px)] bg-black bg-opacity-20 flex items-start justify-start z-50 rounded-t-[5px] p-3">
      <div
        className="bg-white p-5 shadow-lg w-full border-[5px] border-[#ffffff33] rounded-[5px] flex flex-col gap-5">
        <div className={'flex justify-between items-center'}>
          <h2 className={'text-[#737984] text-[14px]'}>{modalType}</h2>
          <button onClick={() => handleModal()}><IoClose size={30}/></button>
        </div>
        <div>
          {children}
        </div>
      </div>
    </div>
  );
};


export default ModalWindow;