import React from 'react'

export const ArrowIcon = ({className}: {className?: string}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 24 24" className={className}>
  <path stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="m20 16-8-8-8 8"/>
</svg>

  )
}
