import React, { useState } from 'react';
import {ChatAssistAi} from '../../components/ChatAssistAi';
import {AIType} from '../../types/chat.types';
import { ScrollArea } from '../../components/ui/scroll-area';
import { cn } from '../../lib/utils';

const data = [
  {id: 1, title: 'Замена техники по причине неисправности'},
  {id: 2, title: 'Поломка стола в блоке В3, офис А1.354'},
  {id: 3, title: 'Отсутствие горячей воды во всех блоках'},
  {id: 4, title: 'Протечка труб блок А3, офис Е1.543'},
  {id: 5, title: 'Поломка стола в блоке В3, офис А1.5'},
  {id: 6, title: 'Поломка стола в блоке В3, офис А1.354'},
  {id: 7, title: 'Поломка стола в блоке В3, офис А1.354'},
  {id: 8, title: 'Поломка стола в блоке В3, офис А1.354'},
];
const HelpDesk = () => {
  const [currentOrderId, setCurrentOrderId] = useState<number>(data[0].id)
  return (
    <div className={'w-full h-full'}>
      <div className="grid grid-cols-[230px_1fr] h-[calc(100vh-200px)] overflow-hidden border rounded-2xl">
        <div className='bg-white h-[calc(100vh-200px)]  rounded-l-lg border-r flex flex-col'>
        <ScrollArea className="flex-1">
         <div className='divide-y'>
            {data.map(item => (
              <div onClick={() => setCurrentOrderId(item.id)} key={item.id} className={cn('p-5 cursor-pointer', currentOrderId === item.id ? "bg-main-green" : "hover:bg-black/5")}>
                  <h4 className={cn(' text-lg font-medium', currentOrderId === item.id ? "text-white" : "text-main-dark-gray")}>Заявка №{item.id}</h4>
                  <p className={cn('text-base !leading-tight', currentOrderId === item.id ? "text-white/70" : "text-main-dark-gray/70")}>{item.title}</p>
              </div>
            ))}
         </div>
        </ScrollArea>
        </div>
        <ChatAssistAi module={AIType.CALL_CENTRE} className="!w-full !rounded-l-none !border-none !max-h-full" isOpen />
      </div>
    </div>
  );
};
export default HelpDesk;
