import {create} from 'zustand';
import {AIType, Message} from "../types/chat.types";

interface ChatState {
  messages: Record<AIType, Message[]>;
  addMessage: (module: AIType, message: Message) => void;
}

export const useChatStore = create<ChatState>((set) => ({
  messages: {
    [AIType.INTEGRATION]: [],
    [AIType.INVENTARIZATION]: [],
    [AIType.ACCOUNTING]: [],
    [AIType.ASSISTANT]: [],
    [AIType.MANAGEMENT]: [],
    [AIType.HELP_DESK]: [],
    [AIType.CALL_CENTRE]: [],
    [AIType.PARKING]: [],
  },
  addMessage: (module, message) =>
    set((state) => ({
      messages: {
        ...state.messages,
        [module]: [...state.messages[module], message],
      },
    })),
}));