import React from "react";
import {Table, TableBody, TableCell, TableHead, TableHeader, TableRow} from "../ui/table";
import {BiCalendar} from "react-icons/bi";

const applications = [
  {
    id: 1,
    status: {
      info: "Передана",
      color: "#E8A23A"
    },
    date: "17.05.2023",
  },
  {
    id: 2,
    status: {
      info: "На подписании",
      color: "rgba(14,120,241,0.7)"
    },
    date: "17.05.2023",
  },
  {
    id: 3,
    status: {
      info: "Выполнена",
      color: "#26B35C"
    },
    date: "17.05.2023",
  },
  {
    id: 4,
    status: {
      info: "Отказана",
      color: "#CE4667"
    },
    date: "17.05.2023",
  },
  {
    id: 5,
    status: {
      info: "Передана",
      color: "#E8A23A"
    },
    date: "17.05.2023",
  },
];
const Application = () => {
  return (
    <div className={'flex-1'}>
      <h2 className={'text-[18px] text-main-dark-gray font-medium mb-2'}>Заявки</h2>
      <Table className={' border-[#E0E0E0] border-2 rounded-[12px] overflow-hidden text-[14px]'}>
        <TableHeader className={'bg-[#6683DE]'}>
          <TableRow className={'text-center'}>
            <TableHead className={"rounded-tl-[12px] w-[100px] text-white"}>№</TableHead>
            <TableHead className={'text-white'}>Статус</TableHead>
            <TableHead className={'rounded-tr-[12px] text-white'}>Дата</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody className={'bg-white'}>
          {applications.map((application) => (
            <TableRow key={application.id} className={'border-[#E0E0E0]'}>
              <TableCell>{application.id}</TableCell>
              <TableCell>
                    <span className={`px-2 py-[7px] rounded-[6px] text-white font-medium`}
                          style={{backgroundColor: application.status.color}}>{application.status.info}</span>
              </TableCell>
              <TableCell>
                <div className={'flex items-center gap-1'}>
                  <BiCalendar size={18}/>
                  {application.date}
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};
export default Application;
