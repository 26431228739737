import {ITableMTC} from "../../types/tables.types";
import {tableMTCData, tableMTCData2} from "../DataTable/mockdata";
import {Table, TableBody, TableCell, TableHead, TableHeader, TableRow} from "../ui/table";
import React from "react";

type TableMTCProps = {
  limit?: number;
  nameFilter?: string;
  conditionFilter?: string;
  locationFilter?: string;
  handleModal: (value:string) => void;
};
const filterMTC = (itemsMTC: ITableMTC[], nameFilter: string, conditionFilter: string, locationFilter: string) => {
  return itemsMTC.filter(item => {
    const matchesName = nameFilter ? item.asset.toLowerCase().includes(nameFilter.toLowerCase()) : true;
    const matchesStatus = conditionFilter ? item.condition.info.toLowerCase() === conditionFilter.toLowerCase() : true;
    const matchesLocation = locationFilter ? item.location.toLowerCase() === locationFilter.toLowerCase() : true;
    return matchesName && matchesStatus && matchesLocation;
  });
};
const TableMTC = ({limit = tableMTCData2.length, nameFilter = '', conditionFilter = '', locationFilter = '', handleModal}:TableMTCProps) => {
  const displayedData = filterMTC(tableMTCData2, nameFilter, conditionFilter, locationFilter).slice(0, limit);
  console.log(displayedData)
  return (
    <>
      <Table className={'border-[#E0E0E0] border-2 rounded-[5px] overflow-hidden text-[14px]'}>
        <TableHeader className={'bg-[#6683DE]'}>
          <TableRow className={'text-center'}>
            <TableHead className={"rounded-tl-[5px] text-white"}>ID</TableHead>
            <TableHead className={'text-white'}>Актив</TableHead>
            <TableHead className={'text-white'}>Локация</TableHead>
            <TableHead className={'text-white'}>Количество</TableHead>
            <TableHead className={'rounded-tr-[5px]  text-white'}>Состояние</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody className={'bg-white'}>
          {displayedData.map((item) => (
            <TableRow key={item.id} className={'border-[#E0E0E0]'} onClick={() => handleModal(item.asset)}>
              <TableCell className={'py-2 px-4'}>{item.id}</TableCell>
              <TableCell className={'py-2 px-4'}>{item.asset}</TableCell>
              <TableCell className={'py-2 px-4'}>{item.location}</TableCell>
              <TableCell className={'py-2 px-4'}>{item.quantity}</TableCell>
              <TableCell className={'py-2 px-4'}>
                <span className={`px-2 py-[7px] rounded-[6px] text-white`}
                      style={{backgroundColor: item.condition.color}}>{item.condition.info}</span>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
};
export default TableMTC;
