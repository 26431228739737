export const objectNames = [
  {
    id: 1,
    building: "Нур Алем",
    assets: 1125,
    requests: 10,
  },
  {
    id: 2,
    building: "Блок С1",
    assets: 541,
    requests: 35,
  },
  {
    id: 3,
    building: "Блок С2",
    assets: 7650,
    requests: 123,
  },
  {
    id: 4,
    building: "Блок С3",
    assets: 230,
    requests: 5,
  },
  {
    id: 5,
    building: "Блок С4",
    assets: 1,
    requests: 1,
  },
  {
    id: 6,
    building: "Блок С5",
    assets: 1,
    requests: 1,
  },
  {
    id: 7,
    building: "Блок С6",
    assets: 1,
    requests: 1,
  },
]