import {Asset, Employee, ITableMTC} from "../../types/tables.types";

export const employeesTableData: Employee[] = [
  {
    id: 1,
    name: "Сауле Оспанова",
    position: "Дизайнер",
    department: "Отдел дизайна",
    manager: "Асылжан Нураи",
    hireDate: "15.03.2020",
    phone: "8 702 864 0982",
    email: "ospsaule@gmail.com",
    status: {
      info: "В декрете",
      color: "rgba(14,120,241,0.7)"
    },
    action: "Сформировать"
  },
  {
    id: 2,
    name: "Зангар Алихайдар",
    position: "Дизайнер",
    department: "Отдел дизайна",
    manager: "Асылжан Нураи",
    hireDate: "22.07.2019",
    phone: "8 777 093 9281",
    email: "zangi4@gmail.com",
    status: {
      info: "Больничный",
      color: "#CE4667"  // Green
    },
    action: "Сформировать"
  },
  {
    id: 3,
    name: "Даниил Пак",
    position: "Программист",
    department: "Отдел разработки",
    manager: "Мерей Жанрип",
    hireDate: "01.02.2021",
    phone: "8 777 912 9032",
    email: "pakmen@gmail.com",
    status: {
      info: "На работе",
      color: "#26B35C"  // Green
    },
    action: "Сформировать"
  },
  {
    id: 4,
    name: "Кирилл Киселев",
    position: "Дизайнер",
    department: "Отдел дизайна",
    manager: "Асылжан Нураи",
    hireDate: "10.10.2018",
    phone: "8 392 940 1212",
    email: "kisel52@gmail.com",
    status: {
      info: "На работе",
      color: "#26B35C"  // Green
    },
    action: "Сформировать"
  },
  {
    id: 5,
    name: "Карим Абдул",
    position: "Менеджер",
    department: "Отдел продаж",
    manager: "Асылжан Нураи",
    hireDate: "20.05.2019",
    phone: "8 778 439 7823",
    email: "kareemabdul@gmail.com",
    status: {
      info: "В отпуске",
      color: "#E8A23A"
    },
    action: "Сформировать"
  },
  {
    id: 6,
    name: "Максим Петров",
    position: "Программист",
    department: "Отдел разработки",
    manager: "Мерей Жанрип",
    hireDate: "05.06.2022",
    phone: "8 702 765 3490",
    email: "petrandmaxim@gmail.com",
    status: {
      info: "На работе",
      color: "#26B35C"
    },
    action: "Сформировать"
  },
  {
    id: 7,
    name: "Алексей Мирзоев",
    position: "Программист",
    department: "Отдел разработки",
    manager: "Артур Микаэлян",
    hireDate: "11.02.2021",
    phone: "8 702 643 2365",
    email: "alexeymirz@gmail.com",
    status: {
      info: "В отпуске",
      color: "#E8A23A"
    },
    action: "Сформировать"
  },
  {
    id: 8,
    name: "Асылжан Нураи",
    position: "Менеджер",
    department: "Отдел продаж",
    manager: "Артур Микаэлян",
    hireDate: "11.02.2021",
    phone: "8 777 364 2531",
    email: "asylzhan123@gmail.com",
    status: {
      info: "На работе",
      color: "#26B35C"  // Green
    },
    action: "Сформировать"
  },
  {
    id: 9,
    name: "Миррай Айхан",
    position: "Дизайнер",
    department: "Отдел дизайна",
    manager: "Асылжан Нураи",
    hireDate: "12.05.2024",
    phone: "8 778 321 1234",
    email: "mirrai02@gmail.com",
    status: {
      info: "В декрете",
      color: "rgba(14,120,241,0.7)"
    },
    action: "Сформировать"
  },
  {
    id: 10,
    name: "Мерей Жанрип",
    position: "Программист",
    department: "Отдел разработки",
    manager: "Мерей Жанрип",
    hireDate: "08.08.2018",
    phone: "8 702 463 2121",
    email: "mereizhan@gmail.com",
    status: {
      info: "Больничный",
      color: "#CE4667"  // Red
    },
    action: "Сформировать"
  },
  {
    id: 11,
    name: "Нурасхан Жандос",
    position: "Менеджер",
    department: "Отдел продаж",
    manager: "Асылжан Нураи",
    hireDate: "03.11.2017",
    phone: "8 777 543 9746",
    email: "nurturzhan@gmail.com",
    status: {
      info: "Больничный",
      color: "#CE4667"  // Red
    },
    action: "Сформировать"
  }
];
export const assetsTableData: Asset[] = [
  {
    id: 1,
    name: "Айтбек Мурат",
    description: "Осушитель воздуха",
    manufacturer: "Kenmore",
    status: {info: "Перемещение", color: "#26B35C"},
    cost: "2 540 000 т",
    documentLink: "Скачать"
  },
  {
    id: 2,
    name: "Асылжан Нураи",
    description: "Стул",
    manufacturer: "Moon",
    status: {info: "Поломка", color: "#E8A23A"},
    cost: "356 000 т",
    documentLink: "Скачать"
  },
  {
    id: 3,
    name: "Миррай Айхан",
    description: "Воздушный фильтр",
    manufacturer: "Whirlpool",
    status: {info: "Ремонт", color: "rgba(14,120,241,0.7)"},
    cost: "410 000 т",
    documentLink: "Скачать"
  },
  {
    id: 4,
    name: "Нурасхан Жандос",
    description: "Стол",
    manufacturer: "Whirlpool",
    status: {info: "Списан", color: "#CE4667"},
    cost: "1 870 000 т",
    documentLink: "Скачать"
  },
  {
    id: 5,
    name: "Нурасхан Жандос",
    description: "Кран",
    manufacturer: "Moon",
    status: {info: "Перемещение", color: "#26B35C"},
    cost: "863 000 т",
    documentLink: "Скачать"
  },
  {
    id: 6,
    name: "Мерей Жанрип",
    description: "Диван",
    manufacturer: "Moon",
    status: {info: "Поломка", color: "#E8A23A"},
    cost: "320 000 т",
    documentLink: "Скачать"
  },
  {
    id: 7,
    name: "Сауле Оспанова",
    description: "Принтер",
    manufacturer: "Kenmore",
    status: {info: "Ремонт", color: "rgba(14,120,241,0.7)"},
    cost: "212 000 т",
    documentLink: "Скачать"
  },
  {
    id: 8,
    name: "Зангар Алихайдар",
    description: "Доска",
    manufacturer: "Kenmore",
    status: {info: "Списан", color: "#CE4667"},
    cost: "1 540 000 т",
    documentLink: "Скачать"
  },
  {
    id: 9,
    name: "Айтбек Мурат",
    description: "Осушитель воздуха",
    manufacturer: "Kenmore",
    status: {info: "Перемещение", color: "#26B35C"},
    cost: "2 540 000 т",
    documentLink: "Скачать"
  },
  {
    id: 10,
    name: "Асылжан Нураи",
    description: "Стул",
    manufacturer: "Moon",
    status: {info: "Поломка", color: "#E8A23A"},
    cost: "356 000 т",
    documentLink: "Скачать"
  },
  {
    id: 11,
    name: "Миррай Айхан",
    description: "Воздушный фильтр",
    manufacturer: "Whirlpool",
    status: {info: "Ремонт", color: "rgba(14,120,241,0.7)"},
    cost: "410 000 т",
    documentLink: "Скачать"
  },
  {
    id: 12,
    name: "Нурасхан Жандос",
    description: "Стол",
    manufacturer: "Whirlpool",
    status: {info: "Списан", color: "#CE4667"},
    cost: "1 870 000 т",
    documentLink: "Скачать"
  },
]

export const tableMTCData: ITableMTC[] = [
  {
    id: 1,
    asset: 'Осушитель воздуха',
    location: 'Нур Алем',
    quantity: 110,
    condition: {
      info: 'Нормальное',
      color: '#26B35C',
    },
  },
  {
    id: 2,
    asset: 'Кресло',
    location: 'Блок С1',
    quantity: 110,
    condition: {
      info: 'Поломка',
      color: '#E8A23A',
    },
  },
  {
    id: 3,
    asset: 'Принтер',
    location: 'Блок С2',
    quantity: 110,
    condition: {
      info: 'Ремонт',
      color: '#CE4667',
    },
  },
  {
    id: 4,
    asset: 'Стол',
    location: 'Блок С3',
    quantity: 110,
    condition: {
      info: 'АПП',
      color: '#0e78f1b3',
    },
  },
  {
    id: 5,
    asset: 'Кондиционер',
    location: 'Блок С4',
    quantity: 75,
    condition: {
      info: 'Нормальное',
      color: '#26B35C',
    },
  },
  {
    id: 6,
    asset: 'Компьютер',
    location: 'Блок С5',
    quantity: 90,
    condition: {
      info: 'Ремонт',
      color: '#CE4667',
    },
  },
  {
    id: 7,
    asset: 'Проектор',
    location: 'Блок С6',
    quantity: 50,
    condition: {
      info: 'Нормальное',
      color: '#26B35C',
    },
  },
  {
    id: 8,
    asset: 'Микроволновка',
    location: 'Блок С7',
    quantity: 30,
    condition: {
      info: 'Поломка',
      color: '#E8A23A',
    },
  },
  {
    id: 9,
    asset: 'Лампа',
    location: 'Блок С8',
    quantity: 200,
    condition: {
      info: 'Нормальное',
      color: '#26B35C',
    },
  },
  {
    id: 10,
    asset: 'Кофеварка',
    location: 'Блок С9',
    quantity: 15,
    condition: {
      info: 'АПП',
      color: '#0e78f1b3',
    },
  },
]
export const generalTableData = [
  {
    id: 1,
    location: "Блок C1",
    floor: "1",
    room: "432",
    condition: {
      info: "Нормальное",
      color: "#26B35C",
    },
    date: "12.05.2025",
    order: "Сформировать",
    reason: "Не подлежит ремонту",
    asset: "Стул",
  },
  {
    id: 2,
    location: "Нур Алем",
    floor: "1",
    room: "123",
    condition: {
      info: "Поломка",
      color: "#E8A23A",
    },
    date: "12.05.2025",
    order: "Сформировать",
    reason: "Истечение даты амортизации",
    asset: "Стол",
  },
  {
    id: 3,
    location: "Блок C1",
    floor: "2",
    room: "234",
    condition: {
      info: "Ремонт",
      color: "#CE4667",
    },
    date: "12.05.2025",
    order: "Сформировать",
    reason: "Не соответствует стандарту",
    asset: "Розетка",
  },
  {
    id: 4,
    location: "Блок C2",
    floor: "3",
    room: "111",
    condition: {
      info: "АПП",
      color: "#0e78f1b3",
    },
    date: "12.05.2025",
    order: "Сформировать",
    reason: "Не подлежит ремонту",
    asset: "Принтер",
  },
  {
    id: 5,
    location: "Блок B3",
    floor: "4",
    room: "423",
    condition: {
      info: "Нормальное",
      color: "#26B35C",
    },
    date: "12.05.2025",
    order: "Сформировать",
    reason: "Истечение даты амортизации",
    asset: "Доска",
  },
  {
    id: 6,
    location: "Блок C2",
    floor: "2",
    room: "435",
    condition: {
      info: "Поломка",
      color: "#E8A23A",
    },
    date: "12.05.2025",
    order: "Сформировать",
    reason: "Не соответствует стандарту",
    asset: "Кран",
  },
  {
    id: 7,
    location: "Блок A2",
    floor: "1",
    room: "754",
    condition: {
      info: "Ремонт",
      color: "#CE4667",
    },
    date: "12.05.2025",
    order: "Сформировать",
    reason: "Не подлежит ремонту",
    asset: "Диван",
  },
  {
    id: 8,
    location: "Нур Алем",
    floor: "3",
    room: "345",
    condition: {
      info: "АПП",
      color: "#0e78f1b3",
    },
    date: "12.05.2025",
    order: "Сформировать",
    reason: "Истечение даты амортизации",
    asset: "Воздушный фильтр",
  },
  {
    id: 9,
    location: "Блок B1",
    floor: "2",
    room: "256",
    condition: {
      info: "Нормальное",
      color: "#26B35C",
    },
    date: "12.05.2025",
    order: "Сформировать",
    reason: "Не подлежит ремонту",
    asset: "Лампа",
  },
  {
    id: 10,
    location: "Блок C3",
    floor: "1",
    room: "312",
    condition: {
      info: "Поломка",
      color: "#E8A23A",
    },
    date: "12.05.2025",
    order: "Сформировать",
    reason: "Истечение даты амортизации",
    asset: "Компьютер",
  },
  {
    id: 11,
    location: "Блок D1",
    floor: "3",
    room: "111",
    condition: {
      info: "Ремонт",
      color: "#CE4667",
    },
    date: "12.05.2025",
    order: "Сформировать",
    reason: "Не соответствует стандарту",
    asset: "Окно",
  },
  {
    id: 12,
    location: "Блок A1",
    floor: "4",
    room: "123",
    condition: {
      info: "АПП",
      color: "#0e78f1b3",
    },
    date: "12.05.2025",
    order: "Сформировать",
    reason: "Не подлежит ремонту",
    asset: "Кресло",
  },
  {
    id: 13,
    location: "Блок C1",
    floor: "3",
    room: "345",
    condition: {
      info: "Нормальное",
      color: "#26B35C",
    },
    date: "12.05.2025",
    order: "Сформировать",
    reason: "Истечение даты амортизации",
    asset: "Стол",
  },
  {
    id: 14,
    location: "Блок B2",
    floor: "2",
    room: "246",
    condition: {
      info: "Поломка",
      color: "#E8A23A",
    },
    date: "12.05.2025",
    order: "Сформировать",
    reason: "Не соответствует стандарту",
    asset: "Проектор",
  },
  {
    id: 15,
    location: "Блок C3",
    floor: "1",
    room: "157",
    condition: {
      info: "Ремонт",
      color: "#CE4667",
    },
    date: "12.05.2025",
    order: "Сформировать",
    reason: "Не подлежит ремонту",
    asset: "Радиатор",
  },
];
export const managingInventoryItems = [
  {
    id: 1,
    title: "Материально-технические ценности",
    due: "3660"
  },
  {
    id: 2,
    title: "На списание",
    due: "120"
  },
  {
    id: 3,
    title: "В закупе",
    due: "80"
  },
  {
    id: 4,
    title: "На ремонт",
    due: "100"
  }
];
export const managingHRItems = [
  {
    id: 1,
    title: "Количество сотрудников",
    quantity: "781 человек"
  },
  {
    id: 2,
    title: "Открытые позиций",
    quantity: "12"
  },
  {
    id: 3,
    title: "ГПХ",
    quantity: "306 договоров"
  },
];
export const positionsTableData = [
  {
    position: "Backend разработчик",
    department: "Разработка",
    manager: "Муратбек Султан",
    action: "Закрыть"
  },
  {
    position: "Frontend разработчик",
    department: "Разработка",
    manager: "Муратбек Султан",
    action: "Закрыть"
  },
  {
    position: "UX/UI дизайнер",
    department: "Дизайн",
    manager: "Айжан Аубакирова",
    action: "Закрыть"
  },
  {
    position: "Менеджер по продажам",
    department: "Продажа",
    manager: "Артур Микаэлян",
    action: "Закрыть"
  },
  {
    position: "Backend разработчик",
    department: "Разработка",
    manager: "Муратбек Султан",
    action: "Закрыть"
  },
  {
    position: "Frontend разработчик",
    department: "Разработка",
    manager: "Муратбек Султан",
    action: "Закрыть"
  },
  {
    position: "UX/UI дизайнер",
    department: "Дизайн",
    manager: "Айжан Аубакирова",
    action: "Закрыть"
  },
  {
    position: "Менеджер по продажам",
    department: "Продажа",
    manager: "Артур Микаэлян",
    action: "Закрыть"
  },
  {
    position: "Backend разработчик",
    department: "Разработка",
    manager: "Муратбек Султан",
    action: "Закрыть"
  },
  {
    position: "Frontend разработчик",
    department: "Разработка",
    manager: "Муратбек Султан",
    action: "Закрыть"
  },
  {
    position: "UX/UI дизайнер",
    department: "Дизайн",
    manager: "Айжан Аубакирова",
    action: "Закрыть"
  },
  {
    position: "Менеджер по продажам",
    department: "Продажа",
    manager: "Артур Микаэлян",
    action: "Закрыть"
  },
  {
    position: "Backend разработчик",
    department: "Разработка",
    manager: "Муратбек Султан",
    action: "Закрыть"
  },
  {
    position: "Frontend разработчик",
    department: "Разработка",
    manager: "Муратбек Султан",
    action: "Закрыть"
  },
  {
    position: "UX/UI дизайнер",
    department: "Дизайн",
    manager: "Айжан Аубакирова",
    action: "Закрыть"
  },
  {
    position: "Менеджер по продажам",
    department: "Продажа",
    manager: "Артур Микаэлян",
    action: "Закрыть"
  }
];

export const tableMTCData2: ITableMTC[] = [
  {
    id: 1,
    asset: 'Маршрутизатор Cisco ISR 4431 (4GE,3NIM,8G FLASH,4G DRAM,IPB)/ISR4431/K9/Cisco',
    location: 'Нур Алем',
    quantity: 110,
    condition: {
      info: 'Нормальное',
      color: '#26B35C',
    },
  },
  {
    id: 2,
    asset: '(Станция)Терминал Polycom Group 700 Codec Only SKU',
    location: 'Блок С1',
    quantity: 110,
    condition: {
      info: 'Поломка',
      color: '#E8A23A',
    },
  },
  {
    id: 3,
    asset: '1-местный диван, конструкция из фанеры, ДВП и ДСП, ножки из хромированной стали. Экокожа или ткань к',
    location: 'Блок С2',
    quantity: 110,
    condition: {
      info: 'Ремонт',
      color: '#CE4667',
    },
  },
  {
    id: 4,
    asset: '19 дюймовая стойка 40U RITTAL',
    location: 'Блок С3',
    quantity: 110,
    condition: {
      info: 'АПП',
      color: '#0e78f1b3',
    },
  },
  {
    id: 5,
    asset: '2 РЕШЕТКИ ИЗ НЕРЖАВЕЮЩЕЙ СТАЛИ GN 1/1 304 325х530х12',
    location: 'Блок С4',
    quantity: 75,
    condition: {
      info: 'Нормальное',
      color: '#26B35C',
    },
  },
  {
    id: 6,
    asset: '2-канальный интерфейс DALI® на DIN-рейке',
    location: 'Блок С5',
    quantity: 90,
    condition: {
      info: 'Ремонт',
      color: '#CE4667',
    },
  },
  {
    id: 7,
    asset: '2-местный диван мягкий диван , 2 стульев, с изогнутыми боковыми ножками из окрашенного ясеня и метал',
    location: 'Блок С6',
    quantity: 50,
    condition: {
      info: 'Нормальное',
      color: '#26B35C',
    },
  },
  {
    id: 8,
    asset: '27" Monitor',
    location: 'Блок С7',
    quantity: 30,
    condition: {
      info: 'Поломка',
      color: '#E8A23A',
    },
  },
  {
    id: 9,
    asset: '3-местный диван, конструкция из фанеры, ДВП и ДСП, ножки из хромированной стали. Экокожа или ткань к',
    location: 'Блок С8',
    quantity: 200,
    condition: {
      info: 'Нормальное',
      color: '#26B35C',
    },
  },
  {
    id: 10,
    asset: '3-местный диван,конструкция из фанеры,ДВП и ДСП,ножки из хромированной стали. Экокожа или ткань кат.',
    location: 'Блок С9',
    quantity: 15,
    condition: {
      info: 'АПП',
      color: '#0e78f1b3',
    },
  },
  {
    id: 11,
    asset: '4K PTZ камера',
    location: 'Блок С10',
    quantity: 60,
    condition: {
      info: 'Нормальное',
      color: '#26B35C',
    },
  },
  {
    id: 12,
    asset: '6-ти канальный инфракрасный приемник в комплекте с аккумулятором/Lingua R6',
    location: 'Блок С11',
    quantity: 40,
    condition: {
      info: 'Ремонт',
      color: '#CE4667',
    },
  },
  {
    id: 13,
    asset: 'Bagutta гардероб см124x64x194h / внешняяотделка: матовый орехcanaletto/ деревянныедверцы: матовый ор',
    location: 'Блок С12',
    quantity: 25,
    condition: {
      info: 'Поломка',
      color: '#E8A23A',
    },
  },
  {
    id: 14,
    asset: 'Connection to a digital voice evacuation sistem PVA-4CR12 (контроллер PVA-4CR12)',
    location: 'Блок С13',
    quantity: 70,
    condition: {
      info: 'Нормальное',
      color: '#26B35C',
    },
  },
  {
    id: 15,
    asset: 'CP3 Система управления корпоративного класса. 8 ИК портов, 1 порт RS232/485/422, 2 порта RS-232, 8 р',
    location: 'Блок С14',
    quantity: 80,
    condition: {
      info: 'Ремонт',
      color: '#CE4667',
    },
  },
];

export const generalTableData2 = [
  {
    id: 70201661,
    location: "Блок C1",
    floor: "1",
    room: "432",
    condition: {
      info: "Нормальное",
      color: "#26B35C",
    },
    date: "30.11.2026",
    order: "Сформировать",
    reason: "Не подлежит ремонту",
    asset: "Маршрутизатор Cisco ISR 4431 (4GE,3NIM,8G FLASH,4G DRAM,IPB)/ISR4431/K9/Cisco",
  },
  {
    id: 70201662,
    location: "Нур Алем",
    floor: "1",
    room: "123",
    condition: {
      info: "Поломка",
      color: "#E8A23A",
    },
    date: "30.11.2026",
    order: "Сформировать",
    reason: "Истечение даты амортизации",
    asset: "Маршрутизатор Cisco ISR 4431 (4GE,3NIM,8G FLASH,4G DRAM,IPB)/ISR4431/K9/Cisco",
  },
  {
    id: 70201104,
    location: "Блок C1",
    floor: "2",
    room: "234",
    condition: {
      info: "Ремонт",
      color: "#CE4667",
    },
    date: "30.11.2026",
    order: "Сформировать",
    reason: "Не соответствует стандарту",
    asset: "(Станция)Терминал Polycom Group 700 Codec Only SKU",
  },
  {
    id: 70201105,
    location: "Блок C2",
    floor: "3",
    room: "111",
    condition: {
      info: "АПП",
      color: "#0e78f1b3",
    },
    date: "30.11.2026",
    order: "Сформировать",
    reason: "Не подлежит ремонту",
    asset: "(Станция)Терминал Polycom Group 700 Codec Only SKU",
  },
  {
    id: 70192254,
    location: "Блок B3",
    floor: "4",
    room: "423",
    condition: {
      info: "Нормальное",
      color: "#26B35C",
    },
    date: "30.11.2026",
    order: "Сформировать",
    reason: "Истечение даты амортизации",
    asset: "1-местный диван, конструкция из фанеры, ДВП и ДСП, ножки из хромированной стали. Экокожа или ткань к",
  },
  {
    id: 70192255,
    location: "Блок C2",
    floor: "2",
    room: "435",
    condition: {
      info: "Поломка",
      color: "#E8A23A",
    },
    date: "30.11.2026",
    order: "Сформировать",
    reason: "Не соответствует стандарту",
    asset: "1-местный диван, конструкция из фанеры, ДВП и ДСП, ножки из хромированной стали. Экокожа или ткань к",
  },
  {
    id: 100250407,
    location: "Блок A2",
    floor: "1",
    room: "754",
    condition: {
      info: "Ремонт",
      color: "#CE4667",
    },
    date: "25.01.2025",
    order: "Сформировать",
    reason: "Не подлежит ремонту",
    asset: "19 дюймовая стойка 40U RITTAL",
  },
  {
    id: 70189666,
    location: "Нур Алем",
    floor: "3",
    room: "345",
    condition: {
      info: "АПП",
      color: "#0e78f1b3",
    },
    date: "30.11.2026",
    order: "Сформировать",
    reason: "Истечение даты амортизации",
    asset: "2 РЕШЕТКИ ИЗ НЕРЖАВЕЮЩЕЙ СТАЛИ GN 1/1 304 325х530х12",
  },
  {
    id: 70201106,
    location: "Блок B1",
    floor: "2",
    room: "256",
    condition: {
      info: "Нормальное",
      color: "#26B35C",
    },
    date: "30.11.2026",
    order: "Сформировать",
    reason: "Не подлежит ремонту",
    asset: "2-канальный интерфейс DALI® на DIN-рейке",
  },
  {
    id: 70201107,
    location: "Блок C3",
    floor: "1",
    room: "312",
    condition: {
      info: "Поломка",
      color: "#E8A23A",
    },
    date: "30.11.2026",
    order: "Сформировать",
    reason: "Истечение даты амортизации",
    asset: "2-канальный интерфейс DALI® на DIN-рейке",
  },
  {
    id: 70189549,
    location: "Блок D1",
    floor: "3",
    room: "111",
    condition: {
      info: "Ремонт",
      color: "#CE4667",
    },
    date: "30.11.2026",
    order: "Сформировать",
    reason: "Не соответствует стандарту",
    asset: "2-местный диван мягкий диван , 2 стульев, с изогнутыми боковыми ножками из окрашенного ясеня и метал",
  },
  {
    id: 70189637,
    location: "Блок A1",
    floor: "4",
    room: "123",
    condition: {
      info: "АПП",
      color: "#0e78f1b3",
    },
    date: "30.11.2026",
    order: "Сформировать",
    reason: "Не подлежит ремонту",
    asset: "2-местный диван мягкий диван , 2 стульев, с изогнутыми боковыми ножками из окрашенного ясеня и метал",
  },
  {
    id: 100248570,
    location: "Блок C1",
    floor: "3",
    room: "345",
    condition: {
      info: "Нормальное",
      color: "#26B35C",
    },
    date: "25.01.2029",
    order: "Сформировать",
    reason: "Истечение даты амортизации",
    asset: "27\" Monitor",
  },
  {
    id: 70192251,
    location: "Блок B2",
    floor: "2",
    room: "246",
    condition: {
      info: "Поломка",
      color: "#E8A23A",
    },
    date: "30.11.2026",
    order: "Сформировать",
    reason: "Не соответствует стандарту",
    asset: "3-местный диван, конструкция из фанеры, ДВП и ДСП, ножки из хромированной стали. Экокожа или ткань к",
  },
  {
    id: 70192252,
    location: "Блок C3",
    floor: "1",
    room: "157",
    condition: {
      info: "Ремонт",
      color: "#CE4667",
    },
    date: "30.11.2026",
    order: "Сформировать",
    reason: "Не подлежит ремонту",
    asset: "3-местный диван, конструкция из фанеры, ДВП и ДСП, ножки из хромированной стали. Экокожа или ткань к",
  },
];
