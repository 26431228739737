import {useCallback, useEffect, useState} from 'react';
import {cn} from '../../lib/utils';
import {AIType, Message} from '../../types/chat.types';
import {AiChatIcon} from '../icons/AiChat.icon';
import {ArrowIcon} from '../icons/Arrow.icon';
import {ChatList} from './ui/chat-list';
import {ChatPanel} from './ui/chat-panel';
import {useScrollAnchor} from '../../hooks/use-scroll-anchor';
import {AlertDestructive} from '../ui/alerts/AlertDestructive';
import {useChatStore} from "../../store/chatStore";

interface ChatAssistAiProps {
  className?: string,
  module: AIType,
  isOpen?: boolean
}

const BACKEND_URL_DOMAIN = 'expoapi.nimbl.tv'

const exampleMessages = [
  {
    id: 1,
    message: 'Напомни дату покупки'
  },
  {
    id: 2,
    message: 'Напомни покупки'
  },
  {
    id: 3,
    message: 'Напомни дату покупки'
  },
  {
    id: 4,
    message: 'Напомни дату доставки'
  }
]

const chatPlaceholder = {
  0: "",
  1: "Инвентаризация, здесь можно проконтролировать процесс работы в инвентарем",
  2: "",
  3: "",
  4: "Можете использовать голосовой чат для уточнения информации по сотрудникам и формированию приказов",
  5: "",
  6: "Это Ваша мониторинговая база, тут можно  ознакомиться с базовой информацией по каждому модулю",
  7: ""
}
export const ChatAssistAi = ({className, module, isOpen: isOpenProps}: ChatAssistAiProps) => {
  const {messages, addMessage} = useChatStore();
  const [input, setInput] = useState('')
  const [isOpen, setIsOpen] = useState(!!isOpenProps)
  const [error, setError] = useState<null | string>(null)

  const handleOpenChat = () => {
    if (isOpen) return
    setIsOpen(true)
  }

  const {messagesRef, scrollRef, visibilityRef, isAtBottom, scrollToBottom} =
    useScrollAnchor()

  ///
  const [socket, setSocket] = useState<WebSocket | null>(null);
  // const [messages, setMessages] = useState<Message[]>([]);
  console.log(messages)
  useEffect(() => {
    const webSocketURL =
      "wss://" +
      BACKEND_URL_DOMAIN +
      "/wss/chat/" +
      module +
      "/";

    const newSocket = new WebSocket(webSocketURL);

    newSocket.onopen = () => {
      setSocket(newSocket);
      console.log("WebSocket connection established");
    };

    newSocket.onmessage = function (e) {
      const data = JSON.parse(e.data) as { message: string, role: 'error' | string }
      if (data.role === 'error') {
        return setError(data.message)
      }
      setError(null)
      // setMessages(prev => [...prev, {content: data.message, role: data.role}])
      addMessage(module, {content: data.message, role: data.role});
    };

    newSocket.onclose = function () {
      console.log("WebSocket connection closed");
      setSocket(null);
    };

    // Cleanup function to close WebSocket when component unmounts or useEffect re-runs
    return () => {
      if (newSocket.readyState === WebSocket.OPEN) {
        newSocket.close();
        console.log("WebSocket connection closed in cleanup");
      }
    };
  }, []);

  const sendMessage = useCallback((text: string) => {
    if (!socket) return;
    console.log('Sending message:', text);  // Debug log
    socket.send(JSON.stringify({ message: text }));
  }, [socket]);

  ///
  return (
    <div onClick={handleOpenChat}
         className={cn('border border-line-color transition-all  bg-white rounded-[5px] overflow-hidden text-[#333333] flex flex-col', className, isOpen ? "max-w-full w-full max-h-[60vh]" : " max-w-[420px] w-[370px] max-h-[370px]")}>
      <div className='flex gap-3 text-[#333333] p-[18px] items-center'>
        <AiChatIcon/>
        <p className='font-medium text-[20px] !leading-none'><span className='text-addit-purple'>AI</span> Ассистент</p>
        {!isOpenProps && <button onClick={() => setIsOpen(false)}
                                 className='bg-[#26B35C] w-[36px] h-[36px] rounded-full ml-auto flex items-center justify-center'>
          <ArrowIcon className={cn('transition-transform', isOpen ? 'rotate-90' : 'rotate-180')}/>
        </button>}
      </div>
      <div ref={scrollRef}
           className='pt-[27px] px-[18px] text-[16px] leading-tight border-y border-line-color min-h-[140px] flex-1 overflow-y-auto relative'>
        <div ref={messagesRef}>
          {messages[module].length ? (
            <>
              <ChatList messages={messages[module]}/>
            </>
          ) : (
            <p>{chatPlaceholder[module] || "Это Ваша мониторинговая база, тут можно  ознакомиться с базовой информацией по каждому модулю"}</p>
          )}
          <div className="w-full h-px" ref={visibilityRef}/>
        </div>
        <div
          className={cn('absolute w-[min(600px,90%)] left-1/2 -translate-x-1/2 top-5 z-50 transition-all', error ? '-translate-y-0 opacity-100' : '-translate-y-[200px] opacity-0')}>
          <AlertDestructive onClose={() => setError(null)}/></div>
      </div>
      <ChatPanel isOpen={isOpen} input={input} setInput={setInput} sendMessage={sendMessage} ai_type={module}/>
    </div>
  );
};
