import React, {useRef, useState} from "react";
import {FaUserLarge} from "react-icons/fa6";
import {IoMdLock} from "react-icons/io";
import {Link, Navigate, useNavigate} from "react-router-dom";
import {useUserStore} from "../../store/store";
import axios from "axios";

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const usernameRef = useRef<HTMLInputElement>(null)
  const passwordRef = useRef<HTMLInputElement>(null)
  const setUser = useUserStore((state) => state.setUser);
  const navigate = useNavigate()
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const errors = []
    if (username === '') {
      errors.push('Your e-mail is missing')
    }
    if (password === '') {
      errors.push('Your password is missing')
    }
    if (errors.length === 0) {
      axios
        .post('/login/', {username, password}, {withCredentials: true})
        .then(response => {
          console.log(response)
          const token = response.data.key
          localStorage.setItem('user-token', token)
          setUser(token)
          navigate('/feed')
        })
        .catch(error => {
          console.log("error", error)
          errors.push('The email or password is incorrect! Please try again')
        })
    }
    setUsername('')
    setPassword('')

  };
  const focusUsernameInput = () => {
    if (usernameRef.current) {
      usernameRef.current.focus();
    }
  };
  const focusPasswordInput = () => {
    if (passwordRef.current) {
      passwordRef.current.focus();
    }
  };
  return (
    <div className={'flex items-center justify-end bg-[#123354] h-[100vh]'}>
      <div className={'w-[70%] flex items-center justify-center'}>
        <img src={'/robot.png'} alt={''} className={'w-72'}/>
      </div>
      <div
        className={'rounded-l-[16px] flex flex-col gap-40 justify-center items-center px-[100px] bg-[#F2F6FF] min-w-[450px] h-screen'}>
        <div>
          <h1 className={'max-w-[182px] text-[30px] text-center'}>Добро Пожаловать!</h1>
        </div>
        <form onSubmit={handleSubmit} className={'flex flex-col items-center gap-6'}>
          <h2 className={'text-[24px] '}>Войти</h2>
          <div onClick={focusUsernameInput} className="flex justify-between items-center w-[316px] h-[62px] bg-white rounded-[12px] px-4">
            <div className={'relative w-[85%]'}>
              <input type="text" id="floating_username" ref={usernameRef}
                     className="block pb-2.5 pt-5 w-full bg-transparent appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                     placeholder=" " value={username}
                     onChange={(e) => setUsername(e.target.value)}/>
              <label htmlFor="floating_username"
                     className="absolute text-[#737984] text-[16px] duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] start-0
                     peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto">
                Имя пользователя
              </label>
            </div>
            <FaUserLarge size={16} color={"#333333"}/>
          </div>
          <div onClick={focusPasswordInput} className="flex justify-between items-center w-[316px] h-[62px] bg-white rounded-[12px] px-4">
            <div className={'relative w-[85%]'}>
              <input type="password" id="floating_password" ref={passwordRef}
                     className="block pb-2.5 pt-5 w-full bg-transparent appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                     placeholder=" " value={password}
                     onChange={(e) => setPassword(e.target.value)}/>
              <label htmlFor="floating_password"
                     className="absolute text-[#737984] text-[16px] duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] start-0 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto">
                Пароль
              </label>
            </div>
            <IoMdLock size={24} color={"#333333"}/>
          </div>
          <button className={'bg-[#26B35C] text-white w-[316px] text-[16px] py-[17px] rounded-[12px]'}>
            Зайти в профиль
          </button>
        </form>
        <div>
        </div>
      </div>
    </div>
  );
};
export default Login;
