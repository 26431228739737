import {Link, useLocation} from "react-router-dom";
import React from "react";
import {AIType} from "../../types/chat.types";
import {ChatAssistAi} from "../../components/ChatAssistAi";

const RoutePage = () => {
  const location = useLocation();
  console.log(location)
  return (
    <div className={'relative flex flex-col gap-[30px]'}>
      <div className={'flex gap-10 flex-row-reverse flex-wrap transition-all'}>
        <ChatAssistAi module={AIType.MANAGEMENT}/>
        <Link to="/inventory">
          <div
            className={`flex flex-col justify-between items-center rounded-[10px] bg-white w-[450px] h-[332px] overflow-hidden`}>
            <div
              className={'flex justify-center items-center p-4 h-[50px] bg-routes-gradient from-[#DAE4F0] to-[#E9F1FA] w-full'}>
              <h2 className={'text-[20px] font-bold text-[#353D52]'}>Инвентаризация</h2>
            </div>
            <div className={'flex flex-col justify-between items-center pt-4 px-4 h-full'}>
              <p className={'text-[14px] text-center text-[#353D52]'}>Тут Вы можете подробнее ознакомиться с имеющимся на данный момент
                инвентарем</p>
              <img src={'/Boxes.png'} alt={'boxes'} className={'w-[280px]'}/>
            </div>
          </div>
        </Link>
        <Link to="/accounting">
          <div
            className={`flex flex-col justify-between items-center rounded-[10px] bg-white w-[450px] h-[332px] overflow-hidden`}>
            <div
              className={'flex justify-center items-center p-4 h-[50px] bg-routes-gradient from-[#DAE4F0] to-[#E9F1FA] w-full'}>
              <h2 className={'text-[20px] font-bold text-[#353D52]'}>Бухгалтерия</h2>
            </div>
            <div className={'flex flex-col justify-between items-center pt-4 px-4 h-full'}>
              <p className={'text-[14px] text-center text-[#353D52]'}>Работа с базами данных 1С</p>
              <img src={'/Accounting.png'} alt={'boxes'} className={'relative w-[200px] top-[50px]'}/>
            </div>
          </div>
        </Link>
      </div>
      <div className={'flex flex-wrap gap-2 justify-between'}>
        <Link to={"/changes"}>
          <div
            className={`flex flex-col justify-between items-center rounded-[10px] bg-white w-[335px] h-[332px] overflow-hidden`}>
            <div
              className={'flex justify-center items-center p-4 h-[50px] bg-routes-gradient from-[#DAE4F0] to-[#E9F1FA] w-full'}>
              <h2 className={'text-[20px] font-bold text-[#353D52]'}>Паркинг</h2>
            </div>
            <div className={'flex flex-col justify-between items-center pt-4 px-4 h-full'}>
              <p className={'text-[14px] text-center text-[#353D52]'}>Тут </p>
              <img src={'/Parking.png'} alt={'boxes'} className={'w-[166px]'}/>
            </div>
          </div>
        </Link>
        <Link to={"/personnelrecords"}>
          <div
            className={`flex flex-col justify-between items-center rounded-[10px] bg-white w-[335px] h-[332px] overflow-hidden`}>
            <div
              className={'flex justify-center items-center p-4 h-[50px] bg-routes-gradient from-[#DAE4F0] to-[#E9F1FA] w-full'}>
              <h2 className={'text-[20px] font-bold text-[#353D52]'}>HR</h2>
            </div>
            <div className={'flex flex-col justify-between items-center pt-4 px-4 h-full'}>
              <p className={'text-[14px] text-center text-[#353D52]'}>Обратиться к HR отделу</p>
              <img src={'/HR.png'} alt={'boxes'} className={'w-[130px]'}/>
            </div>
          </div>
        </Link>
        <Link to={"/dashboard"}>
          <div
            className={`flex flex-col justify-between items-center rounded-[10px] bg-white w-[335px] h-[332px] overflow-hidden`}>
            <div
              className={'flex justify-center items-center p-4 h-[50px] bg-routes-gradient from-[#DAE4F0] to-[#E9F1FA] w-full'}>
              <h2 className={'text-[20px] font-bold text-[#353D52]'}>RCM</h2>
            </div>
            <div className={'flex flex-col justify-between items-center pt-4 px-4 h-full'}>
              <p className={'text-[14px] text-center text-[#353D52]'}>Контролируйте информацию.</p>
              <img src={'/Folder.png'} alt={'boxes'} className={'relative top-[30px] left-[30px] w-[280px]'}/>
            </div>
          </div>
        </Link>
        <Link to={"/helpdesk"}>
          <div
            className={`flex flex-col justify-between items-center rounded-[10px] bg-white w-[335px] h-[332px] overflow-hidden`}>
            <div
              className={'flex justify-center items-center p-4 h-[50px] bg-routes-gradient from-[#DAE4F0] to-[#E9F1FA] w-full'}>
              <h2 className={'text-[20px] font-bold text-[#353D52]'}>Call Center</h2>
            </div>
            <div className={'flex flex-col justify-between items-center pt-4 px-4 h-full'}>
              <p className={'text-[14px] text-center text-[#353D52]'}>Свяжитесь с поддержкой по возникшему вопросу.</p>
              <img src={'/Device.png'} alt={'boxes'} className={'w-[106px]'}/>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
};
export default RoutePage;
