import {ITableGeneral} from "../../types/tables.types";
import {employeesTableData, generalTableData, generalTableData2} from "../DataTable/mockdata";
import {Table, TableBody, TableCell, TableHead, TableHeader, TableRow} from "../ui/table";
import React, {useState} from "react";
import {Select, SelectContent, SelectItem, SelectTrigger, SelectValue} from "../ui/select";
import InputMask from "react-input-mask";

type GeneralTableProps = {
  limit?: number;
  assetFilter?: string;
  conditionFilter?: string;
  locationFilter?: string;
  idFilter?: number;
  floorFilter?: string;
  roomFilter?: string;
  type: string
};
const filterGeneral = (itemsGeneral: ITableGeneral[], assetFilter: string, conditionFilter: string, floorFilter: string, roomFilter: string, idFilter: number, locationFilter: string) => {
  return itemsGeneral.filter(item => {
    const matchesAsset = assetFilter ? item.asset.toLowerCase().includes(assetFilter.toLowerCase()) : true;
    const matchesCondition = conditionFilter ? item.condition.info.toLowerCase() === conditionFilter.toLowerCase() : true;
    const matchesLocation = locationFilter ? item.location.toLowerCase() === locationFilter.toLowerCase() : true;
    const matchesID = idFilter ? item.id === idFilter : true
    const matchesFloor = floorFilter ? item.floor.toLowerCase() === floorFilter.toLowerCase() : true;
    const matchesRoom = roomFilter ? item.room.toLowerCase() === roomFilter.toLowerCase() : true;

    const applyFilter = matchesAsset && matchesCondition && matchesFloor && matchesRoom && matchesID && matchesLocation;
    return applyFilter
  });
};
const GeneralTable = ({limit = generalTableData.length, type}: GeneralTableProps) => {
  const isAsset = type !== "На списание" && type !== "На ремонт" && type !== "В закупе";
  const notAsset = type === "На списание" || type === "На ремонт"
  const stock = type === "В закупе"
  console.log(notAsset)

  const [conditionFilter, setConditionFilter] = useState('');
  const [assetFilter, setAssetFilter] = useState('');
  const [idFilter, setIdFilter] = useState(0);
  const [idInput, setIdInput] = useState('');
  const [floorFilter, setFloorFilter] = useState('');
  const [roomFilter, setRoomFilter] = useState('');
  const [locationFilter, setLocationFilter] = useState('');

  const handleCondition = (value: string) => {
    if (value.trim() === "") {
      setConditionFilter("");
    } else {
      setConditionFilter(value);
    }
  };
  const handleInputIDChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setIdInput(value);

    const numericValue = parseInt(value, 10);
    if (!isNaN(numericValue)) {
      setIdFilter(numericValue);
    } else {
      setIdFilter(0);
    }
  };
  const handleAssetChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAssetFilter(event.target.value);
  };

  const handleLocation = (value: string) => {
    if (value.trim() === "") {
      setLocationFilter("");
      setFloorFilter("");
      setRoomFilter("");
    } else {
      setLocationFilter(value);
      setFloorFilter("");
      setRoomFilter("");
    }
  };

  const handleFloor = (value: string) => {
    if (value.trim() === "") {
      setFloorFilter("");
      setRoomFilter("");
    } else {
      setFloorFilter(value);
      setRoomFilter("");
    }
  };

  const handleRoom = (value: string) => {
    if (value.trim() === "") {
      setRoomFilter("");
    } else {
      setRoomFilter(value);
    }
  };

  const displayedData = filterGeneral(generalTableData2, assetFilter, conditionFilter, floorFilter, roomFilter, idFilter, locationFilter);
  const uniqueLocations = Array.from(new Set(generalTableData2.map(item => item.location)));

  console.log(uniqueLocations, displayedData)
  return (
    <div className={'flex flex-col gap-2.5'}>
      <div className={'flex justify-between items-center'}>
        <h2 className={"text-[20px]"}>{type}</h2>
        <div className={'flex gap-4 justify-end'}>
          {/*----------Location Filter----------*/}
          <Select onValueChange={handleLocation} value={locationFilter}>
            <SelectTrigger className="w-[180px] rounded-[5px] h-[34px] text-[14px] border-[#737984] border-[1px]">
              <SelectValue placeholder="Все Объекты"/>
            </SelectTrigger>
            <SelectContent>
              <SelectItem value={" "} className={'mb-1 cursor-pointer text-main-dark-gray font-medium text-[14px]'}>Все
                Объекты</SelectItem>
              {uniqueLocations
                .map(item => (
                  <SelectItem value={item} className={'mb-1 cursor-pointer font-medium text-[14px]'}
                  >{item}</SelectItem>
                ))}
            </SelectContent>
          </Select>
          {/*----------Floor Filter----------*/}
          <Select onValueChange={handleFloor} disabled={locationFilter === ''} value={floorFilter}>
            <SelectTrigger className="w-[180px] rounded-[5px] h-[34px] text-[14px] border-[#737984] border-[1px]">
              <SelectValue placeholder="Все этажи"/>
            </SelectTrigger>
            <SelectContent>
              <SelectItem value={" "} className={'mb-1 cursor-pointer text-main-dark-gray font-medium text-[14px]'}>Все
                этажи</SelectItem>
              {generalTableData
                .filter(item => item.location === locationFilter)
                .map(item => (
                  <SelectItem value={item.floor} className={'mb-1 cursor-pointer font-medium text-[14px]'}
                  >{item.floor} этаж</SelectItem>
                ))}
            </SelectContent>
          </Select>

          <Select onValueChange={handleRoom} disabled={floorFilter === ''} value={roomFilter}>
            <SelectTrigger className="w-[180px] rounded-[5px] h-[34px] text-[14px] border-[#737984] border-[1px]">
              <SelectValue placeholder="Все кабинеты"/>
            </SelectTrigger>
            <SelectContent>
              <SelectItem value={" "} className={'mb-1 cursor-pointer text-main-dark-gray font-medium text-[14px]'}>Все
                кабинеты</SelectItem>
              {generalTableData
                .filter(item => item.floor === floorFilter && item.location === locationFilter)
                .map(item => (
                  <SelectItem value={item.room} className={'mb-1 cursor-pointer font-medium text-[14px]'}
                  >{item.room} кабинет</SelectItem>
                ))}
            </SelectContent>
          </Select>
          {isAsset &&
            <Select onValueChange={handleCondition}>
              <SelectTrigger className="w-[180px] rounded-[5px] h-[34px] text-[14px] border-[#737984] border-[1px]">
                <SelectValue placeholder="Все акты"/>
              </SelectTrigger>
              <SelectContent>
                <SelectItem value={" "} className={'mb-1 cursor-pointer text-main-dark-gray font-medium text-[14px]'}>Все
                  акты</SelectItem>
                <SelectItem value="Нормальное" className={'mb-1 cursor-pointer font-medium text-[14px]'}
                            style={{backgroundColor: "#26B35C", color: 'white'}}>Нормальное</SelectItem>
                <SelectItem value="Поломка" className={'mb-1 cursor-pointer font-medium text-[14px]'}
                            style={{backgroundColor: "#E8A23A", color: 'white'}}>Поломка</SelectItem>
                <SelectItem value="Ремонт" className={'mb-1 cursor-pointer font-medium text-[14px]'}
                            style={{backgroundColor: "#CE4667", color: 'white'}}>Ремонт</SelectItem>
                <SelectItem value="АПП" className={'mb-1 cursor-pointer font-medium text-[14px]'}
                            style={{backgroundColor: "#0e78f1b3", color: 'white'}}>АПП</SelectItem>
              </SelectContent>
            </Select>
          }
          {isAsset &&
            <InputMask
              mask="9999999999"
              value={idInput}
              onChange={handleInputIDChange}
              placeholder="Введите код актива"
              className="w-[200px] h-[34px] text-[14px] p-2 rounded-[5px] border-[#737984] border-[1px]"
              alwaysShowMask={false}
              inputMode="numeric"
              maskChar={null}
            />
          }
          {!isAsset &&
            <input
              type="text"
              value={assetFilter}
              onChange={handleAssetChange}
              placeholder="Введите наименование"
              className={'w-[200px] h-[34px] text-[14px] p-2 rounded-[5px] border-[#737984] border-[1px]'}
            />
          }
        </div>
      </div>
      <div>
        <Table className={'border-[#E0E0E0] border-2 rounded-[5px] overflow-hidden text-[14px]'}>
          <TableHeader className={'bg-[#6683DE]'}>
            <TableRow className={'text-center'}>
              <TableHead className={"rounded-tl-[5px] text-white"}>ID</TableHead>
              {!isAsset &&
                <TableHead className={'text-white'}>Актив</TableHead>
              }
              <TableHead className={'text-white'}>Объект</TableHead>
              <TableHead className={'text-white'}>Этаж</TableHead>
              <TableHead className={'text-white'}>Кабинет</TableHead>
              {isAsset &&
                <TableHead className={'text-white'}>Состояние</TableHead>
              }
              {isAsset &&
                <TableHead className={'text-white'}>Дата амортизации</TableHead>
              }
              {notAsset &&
                <TableHead className={'text-white'}>Причина</TableHead>
              }
              {!stock &&
                <TableHead className={'rounded-tr-[5px]  text-white'}>Приказ</TableHead>
              }
              {stock &&
                <TableHead className={'rounded-tr-[5px]  text-white'}>Дата закупа</TableHead>
              }
            </TableRow>
          </TableHeader>
          <TableBody className={'bg-white'}>
            {displayedData.map((item) => (
              <TableRow key={item.id} className={'border-[#E0E0E0]'}>
                <TableCell className={'py-2 px-4'}>{item.id}</TableCell>
                {!isAsset &&
                  <TableCell className={'py-2 px-4 overflow-hidden'}>{item.asset}</TableCell>
                }
                <TableCell className={'py-2 px-4'}>{item.location}</TableCell>
                <TableCell className={'py-2 px-4'}>{item.floor}</TableCell>
                <TableCell className={'py-2 px-4'}>{item.room}</TableCell>
                {isAsset &&
                  <TableCell className={'py-2 px-4'}>
                  <span className={`px-2 py-[7px] rounded-[6px] text-white`}
                        style={{backgroundColor: item.condition.color}}>{item.condition.info}</span>
                  </TableCell>
                }
                {isAsset &&
                  <TableCell className={'py-2 px-4'}>{item.date}</TableCell>
                }
                {notAsset &&
                  <TableCell className={'py-2 px-4'}>{item.reason}</TableCell>
                }
                {!stock &&
                  <TableCell className={'py-2 px-4'}>{item.order}</TableCell>
                }
                {stock &&
                  <TableCell className={'py-2 px-4'}>{item.room}</TableCell>
                }
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};
export default GeneralTable;
