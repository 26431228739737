import React from 'react'

export const SendIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20">
    <path stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8.26 10H3.333L1.686 3.446a.552.552 0 0 1-.02-.117c-.018-.6.644-1.018 1.217-.743L18.333 10l-15.45 7.413c-.567.273-1.22-.132-1.217-.722a.55.55 0 0 1 .028-.155L2.916 12.5"/>
  </svg>
  
  )
}
