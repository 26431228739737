export interface Message {
  role: "error" | string;
  content: string;
}

export enum AIType {
  INTEGRATION = 0,
  INVENTARIZATION = 1,
  ACCOUNTING = 2,
  ASSISTANT = 3,
  MANAGEMENT = 4,
  HELP_DESK = 5,
  CALL_CENTRE = 6,
  PARKING = 7,
}
