import { Navigate, Route, Routes } from "react-router-dom";
import Changes from "./pages/Changes/Changes";
import Dashboard from "./pages/Dashboard/Dashboard";
import Helpdesk from "./pages/Helpdesk/Helpdesk";
import { Inventory } from './pages/Inventory';
import { Layout } from './pages/Layout';
import Login from "./pages/Login/Login";
import PersonnelRecords from "./pages/PersonnelRecords/PersonnelRecords";
import RoutePage from "./pages/RoutePage/RoutePage";

function App() {
  return (
    <div>
      <Routes>
        <Route path={'/login'} element={<Login/>} />
        <Route path="/" element={<Layout />}  >
          <Route index element={<Navigate to="/feed" replace />} />
          <Route path={'feed'} element={<RoutePage/>}/>
          <Route path={'dashboard'} element={<Dashboard/>} />
          <Route path={'inventory'} element={<Inventory/>} />
          <Route path={'changes'} element={<Changes/>} />
          <Route path={'personnelrecords'} element={<PersonnelRecords/>} />
          <Route path={'helpdesk'} element={<Helpdesk/>}/>
        </Route>
      </Routes>
    </div>
  );
}

export default App;
