import React, {useState} from 'react'
import {ChatAssistAi} from '../../components/ChatAssistAi'
import {AIType} from '../../types/chat.types'
import {Select, SelectContent, SelectItem, SelectTrigger, SelectValue} from "../../components/ui/select";
import GeneralTable from "../../components/InventoryTables/GeneralTable";
import {objectNames} from "../../components/InventoryTables/inventoryMockdata";
import TableMTC from "../../components/InventoryTables/TableMTC";
import ModalWindow from "../../components/ModalWindow/ModalWindow";
import {managingInventoryItems} from "../../components/DataTable/mockdata";
import {IoIosArrowRoundBack} from "react-icons/io";
import {FaChevronDown} from "react-icons/fa";
import {Link} from "react-router-dom";

export const Inventory = () => {
  const [condition, setCondition] = useState('');
  const [inputText, setInputText] = useState('');
  const [location, setLocation] = useState('')
  const [tableType, setTableType] = useState('')
  const [showModal, setShowModal] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const handleModal = (value?: string) => {
    setShowModal(!showModal)
    if (value) {
      setTableType(value)
    }
    console.log(value)
  }
  const handleChange = (value: string) => {
    if (value.trim() === "") {
      setCondition("");
    } else {
      setCondition(value);
    }
  };
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputText(event.target.value);
  };
  const handleLocation = (value: string) => {
    if (location !== value) {
      setLocation(value)
    } else {
      setLocation("")
    }
    console.log(location)
  }
  console.log(isOpen)
  return (
    <div className={'relative flex flex-col gap-6'}>
      {showModal && (
        <ModalWindow show={showModal} handleModal={handleModal} type={tableType} modalType={"Инвентаризация"}>
          <GeneralTable type={tableType}/>
        </ModalWindow>
      )}
      <div className={'flex gap-2 items-center'}>

        <Link to={'/feed'}>
          <div className={'p-1 border-[1px] border-[#353D52] rounded-full'}>
            <IoIosArrowRoundBack size={24} color={"#353D52"}/>
          </div>
        </Link>
        <h1 className={'text-[#353D52] text-[40px] font-bold leading-10'}>Инвентарь</h1>
      </div>
      <div className='flex gap-6 flex-row-reverse flex-wrap transition-all'>
        <ChatAssistAi module={AIType['INVENTARIZATION']}/>
        {/*<AboutInsurance/>*/}
        <div className='flex-1 bg-white border-[1px] gap-5 border-[#E0E0E0] rounded-[5px] p-8'>
          <div className={'flex justify-between'}>
            {managingInventoryItems.map((item) => (
              <div key={item.id}
                   onClick={item.title === "Материально-технические ценности" ? () => handleLocation("") : () => handleModal(item.title)}
                   className={'flex flex-col justify-between items-start px-9 py-3 rounded-[12px] cursor-pointer'}>
                <h2 className={'text-[#353D52] text-[16px] text-opacity-60'}>{item.title}</h2>
                <p className={'text-[#353D52] text-[32px] font-medium'}>{item.due} <span
                  className={'font-thin text-opacity-60 text-[14px]'}>ед.</span></p>
              </div>
            ))}
          </div>
          <div
            className={`flex flex-wrap gap-2 overflow-hidden px-2.5 h-[${isOpen ? `${Math.ceil(objectNames.length / 3) * 150}px` : `140px`}] justify-center`}
          >
            {objectNames.map((item) => (
              <div key={item.id} onClick={() => handleLocation(item.building)}
                   className={`flex flex-col w-[270px] h-[140px] text-[#353D52] bg-routes-gradient from-[#DAE4F0] to-[#E9F1FA] border-[1px] border-line-color p-6 rounded-[5px] gap-3 ${location === item.building && 'shadow-location-shadow'} cursor-pointer`}>
                <h2 className={'text-[28px] font-medium'}>{item.building}</h2>
                <div className={'flex flex-col'}>
                  <div className={'flex justify-between'}>
                    <p className={'text-[16px] text-[#353D52] text-opacity-60'}>Количество активов</p>
                    <p className={'text-[16px] font-medium'}>{item.assets}</p>
                  </div>
                  <div className={'flex justify-between'}>
                    <p className={'text-[16px] text-[#353D52] text-opacity-60'}>Запросы</p>
                    <p className={'text-[16px] text-[#353D52] font-medium'}>{item.requests}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className={'flex justify-center items-end pt-4'}>
            <div onClick={() => setIsOpen(!isOpen)} className={'border-[1px] border-[#353D52] rounded-full cursor-pointer'}>
              <FaChevronDown size={34} color={'#353D52'} className={`${isOpen && 'rotate-180'} p-[10px]`}/>
            </div>
          </div>
        </div>
      </div>
      <div className={'flex gap-5 justify-between'}>
        <div className={'flex flex-col gap-3 w-full'}>
          <div className={'flex gap-4 justify-end'}>
            <Select onValueChange={handleChange}>
              <SelectTrigger className="w-[180px] rounded-[5px] h-[34px] text-[14px] border-[#353D52] border-opacity-20 border-[1px]">
                <SelectValue placeholder="Все акты"/>
              </SelectTrigger>
              <SelectContent>
                <SelectItem value={" "} className={'mb-1 cursor-pointer text-main-dark-gray font-medium text-[14px]'}>Все
                  акты</SelectItem>
                <SelectItem value="Нормальное" className={'mb-1 cursor-pointer font-medium text-[14px]'}
                            style={{backgroundColor: "#26B35C", color: 'white'}}>Нормальное</SelectItem>
                <SelectItem value="Поломка" className={'mb-1 cursor-pointer font-medium text-[14px]'}
                            style={{backgroundColor: "#E8A23A", color: 'white'}}>Поломка</SelectItem>
                <SelectItem value="Ремонт" className={'mb-1 cursor-pointer font-medium text-[14px]'}
                            style={{backgroundColor: "#CE4667", color: 'white'}}>Ремонт</SelectItem>
                <SelectItem value="АПП" className={'mb-1 cursor-pointer font-medium text-[14px]'}
                            style={{backgroundColor: "#0e78f1b3", color: 'white'}}>АПП</SelectItem>
              </SelectContent>
            </Select>
            <input
              type="text"
              value={inputText}
              onChange={handleInputChange}
              placeholder="Введите наименование"
              className={'w-[200px] h-[34px] text-[14px] p-2 rounded-[5px] border-[#353D52] border-opacity-20 border-[1px]'}
            />
          </div>
          {/*<Assets statusFilter={status} nameFilter={inputText}/>*/}
          <TableMTC nameFilter={inputText} conditionFilter={condition} locationFilter={location}
                    handleModal={handleModal}/>
        </div>
      </div>
    </div>
  )
}
