
export const AiChatIcon = ({className}: {className?: string}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="35" height="36" fill="none" viewBox="0 0 43 44" className={className}>
  <mask id="a" width="43" height="44" x="0" y="0" maskUnits="userSpaceOnUse" >
    <path fill="#fff" d="M21.5 43.84C9.643 43.84 0 34.008 0 21.92 0 9.835 9.644 0 21.5 0 33.353 0 43 9.834 43 21.92c0 12.088-9.645 21.92-21.5 21.92Z"/>
  </mask>
  <g mask="url(#a)">
    <path fill="#123354" d="M21.5 43.843C9.643 43.843 0 34.01 0 21.923 0 9.836 9.644.002 21.5.002 33.353.002 43 9.836 43 21.922c0 12.088-9.645 21.92-21.5 21.92Z"/>
    <path fill="#E2F0FE" d="M24.924 32.877h-6.846v4.685h6.846v-4.685Zm6.851-5.754c2.252 0 4.094-.543 4.094-3.652 0-3.108-1.842-3.65-4.094-3.65v7.302Z"/>
    <path fill="#fff" d="M8.668 25.337c0 4.59 3.684 8.346 8.185 8.346h9.295c4.502 0 8.185-3.755 8.185-8.346V22.95c0-4.591-3.683-8.346-8.185-8.346h-9.295c-4.502 0-8.185 3.755-8.185 8.346v2.387Z"/>
    <path fill="#E2F0FE" d="M26.15 29.265c2.769 0 5.022-2.297 5.022-5.12 0-2.825-2.253-5.122-5.023-5.122h-9.294c-2.77 0-5.023 2.297-5.023 5.122 0 2.823 2.254 5.12 5.023 5.12h9.294Z"/>
    <path fill="#fff" d="M25.082 17.734c0-2.296-.532-4.173-3.581-4.173-3.05 0-3.581 1.877-3.581 4.173h7.162ZM19.953 8.93c0-.872.693-1.578 1.547-1.578.853 0 1.549.706 1.549 1.577 0 .872-.694 1.579-1.55 1.579-.855 0-1.546-.706-1.546-1.579Z"/>
    <path fill="#E2F0FE" d="M22.575 9.252c0-.872-.693-1.578-1.546-1.578-.215 0-.42.045-.605.125.279-.276.66-.447 1.08-.447.853 0 1.546.706 1.546 1.577a1.58 1.58 0 0 1-.944 1.455c.29-.287.47-.687.47-1.132Z"/>
    <path fill="#fff" d="M22.018 10.07h-1.034v3.958h1.034V10.07Z"/>
    <path fill="#E2F0FE" d="M26.148 14.604H24.29c3.766 0 8.313 3.755 8.313 8.346v2.387c0 4.59-3.998 8.346-8.501 8.346h2.046c4.502 0 8.186-3.755 8.186-8.346V22.95c0-4.591-3.684-8.346-8.186-8.346Z"/>
    <path fill="#fff" d="M9.98 19.895c-1.647.23-2.847 1.067-2.847 3.576 0 2.375 1.09 3.257 2.62 3.538.278.05.485-7.15.227-7.114Z"/>
    <path fill="#123354" d="M18.342 24.145c0-.758-.604-1.372-1.344-1.372-.74 0-1.346.614-1.346 1.372 0 .757.602 1.37 1.346 1.37.743 0 1.344-.613 1.344-1.37Zm9.008 0c0-.758-.602-1.372-1.345-1.372s-1.345.614-1.345 1.372c0 .757.602 1.37 1.345 1.37s1.344-.613 1.344-1.37Z"/>
    <path fill="#fff" d="M29.387 42.234c0-3.733-2.79-6.05-7.885-6.05-5.093 0-7.885 2.317-7.885 6.05 0 3.733 4.224 2.981 7.885 2.981 3.662 0 7.885.75 7.885-2.981Z"/>
    <path fill="#E2F0FE" d="M27.81 42.232c0 .24-.017.46-.05.664 1.093-.347 1.626-.58 1.626-.58v-.083c0-2.786-1.554-4.784-4.46-5.618 0 0 2.884.99 2.884 5.618Z"/>
  </g>
</svg>

  )
}
