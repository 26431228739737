import React from "react";
import {FaChevronDown} from "react-icons/fa";

const activityLog = [
  {
    id: 1,
    desc: "Алексей Мирзоев вышел в отпуск на период 12.05.2024 - 19.05.2024",
    time: "20 минут назад"
  },
  {
    id: 2,
    desc: "Миррай Айхан вышла в декрет 12.05.2024",
    time: "30 минут назад"
  },
  {
    id: 3,
    desc: "Нурасхан Жандос подписал 12 заявку и отправил на согласование в отдел маркетинга",
    time: "1 час назад"
  },
  {
    id: 4,
    desc: "Мерей Жанрип изменил статус актива воздушный фильтр на ремонт",
    time: "Вчера"
  },
  {
    id: 5,
    desc: "Заявка №12 отправлена в техподдержку",
    time: "Позавчера"
  }
];
const events = [
  {
    id: 1,
    title: "Собрание отдела маркетинг по теме смены принципов управления процессами",
    due: "Через 3 дня"
  },
  {
    id: 2,
    title: "Презентация последних разработок по новому продукту",
    due: "Через 6 дней"
  },
  {
    id: 3,
    title: "Мероприятие в честь дня Независимости",
    due: "Через 2 недели"
  }
];
const taskLog = [
  {
    id: 1,
    desc: "Нужно совершить проверку инвентаря блока V3",
    deadline: "До 27.05.2024"
  },
  {
    id: 2,
    desc: "В блоке A1 отсутствует электричество, требуется техосмотр электропитания",
    deadline: "До 17.05.2024"
  },
  {
    id: 3,
    desc: "Техника блока V3, офис 353 вышла из строя, требуется замена",
    deadline: "До 16.05.2024"
  }
];

const Changes = () => {
  return (
    <div className={'flex flex-col gap-8'}>
      <div>
        <h2 className={'text-[20px] font-medium text-main-dark-gray mb-2 '}>Последние изменения</h2>
        <div className={'flex flex-col gap-4'}>
          {activityLog.map((item) => (
            <div key={item.id} className={'flex items-center gap-2'}>
              <img src={'/tableUser.png'} alt={''} className={'w-[35px]'}/>
              <div className={'flex flex-col'}>
                <span className={'text-[16px] font-medium text-main-dark-gray'}>{item.desc}</span>
                <span className={'text-[#737984] text-[14px]'}>{item.time}</span>
              </div>
            </div>
          ))
          }
        </div>
        <div className={'flex justify-center my-4'}>
          <FaChevronDown size={18} color={'#737984'}/>
        </div>
        <hr/>
      </div>
      {/*----------Предупреждения----------*/}
      <div>
        <h2 className={'text-[20px] font-medium text-[#333333] mb-2'}>Предупреждения</h2>
        <div className={'flex flex-col gap-5'}>
          {taskLog.map((task) => (
            <div key={task.id} className={'flex flex-col'}>
              <span className={'text-[16px] font-medium text-main-dark-gray'}>{task.desc}</span>
              <span className={'text-[#737984] text-[14px]'}>{task.deadline}</span>
            </div>
          ))}
        </div>
        <div className={'flex justify-center my-4'}>
          <FaChevronDown size={18} color={'#737984'}/>
        </div>
        <hr/>
      </div>
      {/*----------Предстоящие события----------*/}
      <div>
        <h2 className={'text-[20px] font-medium text-[#333333] mb-2'}>Предстоящие события</h2>
        <div className={'flex flex-col w-full gap-2'}>
          {events.map((item) => (
            <div className={'flex flex-col gap-1'}>
              <h2 className={'text-[16px] font-medium text-main-dark-gray'}>{item.title}</h2>
              <p className={'text-[#737984] text-[14px]'}>{item.due}</p>
            </div>
          ))}
        </div>
        <div className={'flex justify-center my-4'}>
          <FaChevronDown size={18} color={'#737984'}/>
        </div>
      </div>
    </div>
  );
};
export default Changes;
