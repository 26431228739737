import React from "react";
import {Table, TableBody, TableCell, TableHead, TableHeader, TableRow} from "../ui/table";
import {Asset} from "../../types/tables.types";
import {assetsTableData} from "../DataTable/mockdata";



const filterAssets = (assets: Asset[], nameFilter: string, statusFilter: string) => {
  return assets.filter(asset => {
    const matchesName = nameFilter ? asset.name.toLowerCase().includes(nameFilter.toLowerCase()) : true;
    const matchesStatus = statusFilter ? asset.status.info.toLowerCase() === statusFilter.toLowerCase() : true;
    return matchesName && matchesStatus;
  });
};
const Assets = ({limit = assetsTableData.length, nameFilter = '', statusFilter = ''}) => {

  const displayedAssets = filterAssets(assetsTableData, nameFilter, statusFilter).slice(0, limit);
  console.log(displayedAssets)
  return (
    <>
      <Table className={'border-[#E0E0E0] border-2 rounded-[12px] overflow-hidden text-[14px]'}>
        <TableHeader className={'bg-[#6683DE]'}>
          <TableRow className={'text-center'}>
            <TableHead className={"rounded-tl-[12px] text-white"}>ID</TableHead>
            <TableHead className={'text-white'}>Запрос</TableHead>
            <TableHead className={'text-white'}>Описание</TableHead>
            <TableHead className={'text-white'}>Производитель</TableHead>
            <TableHead className={'text-white'}>Акт</TableHead>
            <TableHead className={'text-white'}>Стоимость</TableHead>
            <TableHead className={'rounded-tr-[12px] text-white'}>Документ</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody className={'bg-white'}>
          {displayedAssets.map((asset) => (
            <TableRow key={asset.id} className={'border-[#E0E0E0]'}>
              <TableCell className={'py-2 px-4'}>{asset.id}</TableCell>
              <TableCell className={'flex items-center gap-1 py-2 px-4'}>
                <img src={'/tableUser.png'} alt={''} className={'w-[24px]'}/>
                <span>{asset.name}</span>
              </TableCell>
              <TableCell className={'py-2 px-4'}>{asset.description}</TableCell>
              <TableCell className={'py-2 px-4'}>{asset.manufacturer}</TableCell>
              <TableCell className={'py-2 px-4'}>
                <span className={`px-2 py-[7px] rounded-[6px] text-white`}
                      style={{backgroundColor: asset.status.color}}>{asset.status.info}</span>
              </TableCell>
              <TableCell className={'py-2 px-4'}>{asset.cost}</TableCell>
              <TableCell className={'py-2 px-4 text-blue-500 underline cursor-pointer'}>{asset.documentLink}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
};
export default Assets;
