import { AlertCircle, X } from "lucide-react"

import {
  Alert,
  AlertTitle,
} from "../alert"


interface AlertDestructiveProps {
  onClose: () => void
}

export function AlertDestructive({onClose}: AlertDestructiveProps) {
  return (
    <Alert variant="destructive" className='flex !items-center bg-white relative' >
      <AlertCircle className="h-4 w-4 !top-1/2 !-translate-y-1/2" />
      <AlertTitle>
      Пожалуйста, повторите Ваш запрос
      </AlertTitle>
      <button onClick={onClose} className='h-4 w-4 ml-auto shrink-0  flex items-center justify-center'><X size={16} className='!static !top-0 !left-0 w-4 h-4 shrink-0'/></button>
    </Alert>
  )
}
