import {useEffect, useState} from 'react';
import {Outlet, useLocation, useNavigate} from 'react-router-dom';
import Navbar from '../components/Navbar/Navbar';
import {useUserStore} from "../store/store";

export const Layout = () => {
  const {setUser} = useUserStore(state => state)
  const [isLoading, setIsLoading] = useState(true)
  const navigate = useNavigate()
  const location = useLocation();
  useEffect(() => {
    const token = localStorage.getItem('user-token');
    if (token) {
      setUser(token)
    }
    console.log(token)
    setIsLoading(false)
    if (!token) {
      navigate('/login', {replace: true});
    }
  },[]);

  if (isLoading) {
    return <div>Loading...</div>
  }

  return (
    <div className={'bg-main-gradient from-[#E8F0F9] via-[#CBD9ED] to-[#CBD9ED]'}>
      <Navbar/>
      <div className={'flex'}>
        {/*<Sidebar/>*/}
        <div className={`w-[100%] p-[34px]`}>
          <Outlet/>
        </div>
      </div>
    </div>
  )
}
