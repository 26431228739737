// Inspired by Chatbot-UI and modified to fit the needs of this project
// @see https://github.com/mckaywrigley/chatbot-ui/blob/main/components/Chat/ChatMessage.tsx

// import remarkGfm from 'remark-gfm'
// import remarkMath from 'remark-math'

import {cn} from '../../../lib/utils';
import {Message} from '../../../types/chat.types';
import {MemoizedReactMarkdown} from './markdown';

export interface ChatMessageProps {
  message: Message;
}

export function ChatMessage({message, ...props}: ChatMessageProps) {
  return (
    <div className={cn('group relative mb-4 flex flex-col items-start text-main-dark-gray')} {...props}>
      <div className="text-lg font-medium select-none">{message.role === 'user' ? 'Вы: ' : <p><span className='text-[#6683DE]'>AI</span> Ассистент:</p> }</div>
      <div className="flex-1 px-1 ml-4 space-y-2 overflow-hidden">
        <MemoizedReactMarkdown
          className="prose break-words dark:prose-invert prose-p:leading-relaxed prose-pre:p-0"
          //   remarkPlugins={[remarkGfm, remarkMath]}
          components={{
            p({children}) {
              return <p className="mb-2 last:mb-0">{children}</p>;
            },
          }}>
          {message.content}
        </MemoizedReactMarkdown>
        {/* <ChatMessageActions message={message} /> */}
      </div>
    </div>
  );
}
