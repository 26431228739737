import {useEffect, useRef, useState} from 'react';
import axios from 'axios';
import {AIType} from '../types/chat.types';
import {useUserStore} from '../store/store';

const getAudioDevices = async () => {
  const devices = await navigator.mediaDevices.enumerateDevices();
  return devices.filter(({kind}) => kind === 'audioinput');
};


interface Props {
  ai_type: AIType;
}

export const useAudioRecords = ({ai_type}: Props) => {
  const token = useUserStore((state) => state.user);
  const audioEl = useRef<HTMLAudioElement>(null);
  const [recorderState, setRecorderState] = useState<MediaRecorder | null>(null);
  const [isRecording, setIsRecording] = useState(false);

  const sendAudio = async (audioBlob: Blob) => {
    try {

      const formData = new FormData();
      formData.append('ai_type', ai_type.toString());
      formData.append('audio_file', audioBlob, 'hey.mp3');
      console.log(formData)
      const response = await axios.post('https://expoapi.nimbl.tv/en/api/main/messages/', formData, {
        headers: {
          Authorization: `Token ${token}`,
        },
      });
      if (response.status === 201) {
        console.log('@response Good');
      }
    } catch (error) {
      console.log('@sendAudio error', error);
    } 
  };

  const handleRecord = () => {
    /** Вкл/Выкл запись микрофона */
    const toggleRecording = async () => {
      if (!recorderState) {
        try {
          const deviceId = await getAudioDevices();
          // Если audio = true будет использоваться устройство по умолчанию
          const audio = deviceId ? {advanced: deviceId} : true;
          const mediaStream = await navigator.mediaDevices.getUserMedia({audio});

          setRecorderState(new MediaRecorder(mediaStream));
          setIsRecording(true);
        } catch (err) {
          console.log(err);
        }
      } else if (recorderState.state !== 'inactive') {
        // record stop
        recorderState.stop();
      }
    };
    toggleRecording();
  };

  useEffect(() => {
    const recorder = recorderState;

    if (recorder && recorder.state === 'inactive') {
      recorder.start();
      const chunks: Blob[] = [];

      recorder.ondataavailable = (e) => {
        /** данные доступны после вызова recorder.stop(). */
        chunks.push(e.data);
      };

      recorder.onstop = () => {
        const AUDIO_BLOB = new Blob(chunks, {type: 'audio/mp3'});

        if (recorder) {
          sendAudio(AUDIO_BLOB); // Отправка аудио
          audioEl.current?.setAttribute('src', window.URL.createObjectURL(AUDIO_BLOB));
        }
        // reset
        RESET_All();
        console.log('@recorder stopped');
      };
    }

    return () => {
      if (recorder) {
        recorder.stream.getAudioTracks().forEach((track) => track.stop());
        console.log('@Снести все записи MediaStream');
      }
    };
    // Обновление хука только при изменении recorderState
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recorderState]);

  const RESET_All = () => {
    setRecorderState(null);
    setIsRecording(false);
  };

  return {
    handleRecord,
    audioEl,
    isRecording,
  };
};
