import React, {useState} from "react";
import {TbEdit} from "react-icons/tb";
import {FiSettings} from "react-icons/fi";
import {FaChevronDown} from "react-icons/fa";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {useUserStore} from "../../store/store";
import {IoSettingsOutline} from "react-icons/io5";
import {GoQuestion} from "react-icons/go";

const Navbar = () => {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const setUser = useUserStore((state) => state.setUser);
  const navigate = useNavigate()
  const handleLogout = () => {
    localStorage.removeItem('user-token');
    setUser('');
    navigate('/login');
  };
  return (
    <div className={'flex justify-between py-[15px] text-white'}>
      <div className={'w-[10%] flex justify-center px-[18px]'}>
        <Link to={'/feed'}><img src={'/qazExpoLogoBlack.png'} alt={''} className={'w-[80px] h-[50px]'}/></Link>
      </div>
      <div className={'w-[90%] px-[30px] flex justify-end gap-[10px]'}>
        <div className={'flex gap-[10px]'}>
          {/*{location.pathname === '/dashboard' && (*/}
          {/*  <div className={'flex items-center gap-2'}>*/}
          {/*    <TbEdit size={24} color={'white'}/>*/}
          {/*    <p className={'text-[14px]'}>Изменить</p>*/}
          {/*  </div>*/}
          {/*)}*/}
          <button><GoQuestion size={50} color={'black'} className={'p-[15px] bg-white rounded-full'}/></button>
          <button><IoSettingsOutline size={50} color={'black'} className={'p-[15px] bg-white rounded-full'}/></button>
          {/*<div*/}
          {/*  className={`w-[80px] relative p-2 flex gap-1 justify-end ${isOpen ? 'flex-col items-end' : 'flex-сol'}`}>*/}
          {/*  <div onClick={() => setIsOpen(!isOpen)} className={`flex gap-1 items-center ${isOpen ? 'mb-[11px]' : ''} `}>*/}
          {/*    /!*<FiSettings size={24} color={'black'}/>*!/*/}
          {/*    <IoSettingsOutline size={50} color={'black'} className={'p-[15px] bg-white rounded-full'}/>*/}
          {/*  </div>*/}
          {/*  /!*{isOpen && (*!/*/}
          {/*  /!*  <div className={'absolute top-[40px]'}>*!/*/}
          {/*  /!*    <button onClick={handleLogout} className={'text-white font-medium text-[14px]'}>Выйти</button>*!/*/}
          {/*  /!*  </div>*!/*/}
          {/*  /!*)}*!/*/}
          {/*</div>*/}
        </div>
        <div className={'flex items-center gap-3'}>
          <img src={'/user.png'} alt={'userImage'} className={'w-[50px] h-[50px]'}/>
        </div>
      </div>
    </div>
  );
};
export default Navbar;
