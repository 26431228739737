import React, {useState} from "react";
import {Table, TableBody, TableCell, TableHead, TableHeader, TableRow} from "../ui/table";
import {Employee, IVacancy} from "../../types/tables.types";
import {DataTable} from "../DataTable/DataTable";
import {employeesColumnHeaders} from "../DataTable/columns";
import {employeesTableData, positionsTableData} from "../DataTable/mockdata";
import {Select, SelectContent, SelectItem, SelectTrigger, SelectValue} from "../ui/select";

const filterEmployees = (employees: Employee[], nameFilter: string, statusFilter: string, departmentFilter: string) => {
  return employees.filter(employee => {
    const matchesName = nameFilter ? employee.name.toLowerCase().includes(nameFilter.toLowerCase()) : true;
    const matchesStatus = statusFilter ? employee.status.info === statusFilter : true;
    const matchesDepartment = departmentFilter ? employee.department === departmentFilter : true;
    return matchesName && matchesStatus && matchesDepartment;
  });
};
const EmployeesTable = ({limit = employeesTableData.length, type = "Сотрудники"}) => {
  const [status, setStatus] = useState('');
  const [department, setDepartment] = useState('');
  const [inputText, setInputText] = useState('');

  const handleChange = (value: string) => {
    if (value.trim() === "") {
      setStatus("");
    } else {
      setStatus(value);
    }
  };
  const handleDepartmentChange = (value: string) => {
    if (value.trim() === "") {
      setDepartment("");
    } else {
      setDepartment(value);
    }
  };
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputText(event.target.value);
  };
  const displayedEmployees = filterEmployees(employeesTableData, inputText, status, department).slice(0, limit);
  const uniqueDepartments = Array.from(new Set(employeesTableData.map(item => item.department)));
  console.log(displayedEmployees)
  const [expandedRow, setExpandedRow] = useState<number | null>(null);
  const handleRowClick = (id: number) => {
    setExpandedRow(expandedRow === id ? null : id);  // Toggle expanded row
  };
  return (
    <div>
      <div className={'flex justify-between'}>
        <h2 className={'text-[20px] text-main-dark-gray font-medium mb-6'}>{type}</h2>
        <div className={'flex gap-4'}>
          <Select onValueChange={handleDepartmentChange}>
            <SelectTrigger className="w-[180px] rounded-[12px] h-[42px] text-[16px] border-[#737984] border-[1px]">
              <SelectValue placeholder="Все Департаменты"/>
            </SelectTrigger>
            <SelectContent>
              <SelectItem value={" "} className={'mb-1 cursor-pointer font-medium'}>Все Департаменты</SelectItem>
              {uniqueDepartments.map(item => (
                <SelectItem value={item} className={'mb-1 cursor-pointer font-medium'}>{item}</SelectItem>
              ))}
            </SelectContent>
          </Select>
          <Select onValueChange={handleChange}>
            <SelectTrigger className="w-[180px] rounded-[12px] h-[42px] text-[16px] border-[#737984] border-[1px]">
              <SelectValue placeholder="Все статусы"/>
            </SelectTrigger>
            <SelectContent>
              <SelectItem value={" "} className={'mb-1 cursor-pointer font-medium'}>Все статусы</SelectItem>
              <SelectItem value="На работе" className={'mb-1 cursor-pointer font-medium'}
                          style={{backgroundColor: "#26B35C", color: 'white'}}>На
                работе</SelectItem>
              <SelectItem value="В отпуске" className={'mb-1 cursor-pointer font-medium'}
                          style={{backgroundColor: "#E8A23A", color: 'white'}}>В
                отпуске</SelectItem>
              <SelectItem value="В декрете" className={'mb-1 cursor-pointer font-medium'}
                          style={{backgroundColor: "rgba(14,120,241,0.7)", color: 'white'}}>В
                декрете</SelectItem>
              <SelectItem value="Больничный" className={'mb-1 cursor-pointer font-medium'}
                          style={{backgroundColor: "#CE4667", color: 'white'}}>Больничный</SelectItem>
            </SelectContent>
          </Select>
          <input
            type="text"
            value={inputText}
            onChange={handleInputChange}
            placeholder="Введите имя сотрудника"
            className={'w-[320px] h-[42px] text-[16px] p-2 rounded-[12px] border-[#737984] border-[1px]'}
          />
        </div>
      </div>
      <Table className={'border-[#E0E0E0] border-2 rounded-[12px] overflow-hidden text-[16px]'}>
        <TableHeader className={'bg-[#6683DE]'}>
          <TableRow className={'text-center'}>
            <TableHead className={"rounded-tl-[12px] w-[100px] text-white"}>Сотрудник/департамент</TableHead>
            <TableHead className={'text-white'}>Номер</TableHead>
            <TableHead className={'text-white'}>Почта</TableHead>
            <TableHead className={'text-white'}>Статус</TableHead>
            <TableHead className={'rounded-tr-[12px] text-white'}>Приказ</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody className={'bg-white'}>
          {displayedEmployees.map((employee) => (
            <>
              <TableRow onClick={() => handleRowClick(employee.id)} key={employee.id}
                        className={'border-[#E0E0E0] text-main-dark-gray'}>
                <TableCell className={'w-[300px] flex items-center gap-2 py-2 px-4'}>
                  <img src={'/tableUser.png'} alt={''} className={'w-[40px]'}/>
                  <div className={'flex flex-col'}>
                    <span className={'text-[16px] font-medium'}>{employee.name}</span>
                    <span className={'text-[#737984] text-[14px]'}>{employee.department}</span>
                  </div>
                </TableCell>
                <TableCell className={'w-[250px] py-2 px-4'}>{employee.phone}</TableCell>
                <TableCell className={'py-2 px-4'}>{employee.email}</TableCell>
                <TableCell className={'py-2 px-4'}>
                  <span className={`px-2 py-[7px] rounded-[6px] text-white font-medium`}
                        style={{backgroundColor: employee.status.color}}>{employee.status.info}</span>
                </TableCell>
                <TableCell className={'py-2 px-4'}>{employee.action}</TableCell>
              </TableRow>
              {expandedRow === employee.id && (
                <TableRow>
                  <TableCell colSpan={3} className={'flex flex-col gap-2 py-2 px-4'}>
                    <p className={'text-[14px]'}><span
                      className={'font-medium'}>Департамент:</span> {employee.position}
                    </p>
                    <p className={'text-[14px]'}><span className={'font-medium'}>Руководитель:</span> {employee.manager}
                    </p>
                  </TableCell>
                  <TableCell className={'align-top py-2 px-4'} colSpan={3}>
                    <p className={'text-[14px]'}><span
                      className={'font-medium'}>Дата трудоустройства:</span> {employee.hireDate}</p>
                  </TableCell>
                  <TableCell/>
                </TableRow>
              )}
            </>
          ))}
        </TableBody>
      </Table>
      <hr/>
    </div>
  );
};
export default EmployeesTable;

const filterVacancies = (vacancies: IVacancy[], vacancyFilter: string, departmentFilter: string) => {
  return vacancies.filter(vacancy => {
    const matchesName = vacancyFilter ? vacancy.position.toLowerCase().includes(vacancyFilter.toLowerCase()) : true;
    const matchesDepartment = departmentFilter ? vacancy.department === departmentFilter : true;
    return matchesName && matchesDepartment;
  });
};
export const VacanciesTable = ({type = "Открытые позиции"}) => {
  const [department, setDepartment] = useState('');
  const [inputText, setInputText] = useState('');

  const handleDepartmentChange = (value: string) => {
    if (value.trim() === "") {
      setDepartment("");
    } else {
      setDepartment(value);
    }
  };
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputText(event.target.value);
  };
  const displayedVacancies = filterVacancies(positionsTableData, inputText, department);
  const uniqueDepartments = Array.from(new Set(positionsTableData.map(item => item.department)));
  console.log(displayedVacancies)
  return (
    <div>
      <div className={'flex justify-between'}>
        <h2 className={'text-[20px] text-main-dark-gray font-medium mb-6'}>{type}</h2>
        <div className={'flex gap-4'}>
          <Select onValueChange={handleDepartmentChange}>
            <SelectTrigger className="w-[200px] rounded-[12px] h-[42px] text-[16px] border-[#737984] border-[1px]">
              <SelectValue placeholder="Все Департаменты"/>
            </SelectTrigger>
            <SelectContent>
              <SelectItem value={" "} className={'mb-1 cursor-pointer font-medium'}>Все Департаменты</SelectItem>
              {uniqueDepartments.map(item => (
                <SelectItem value={item} className={'mb-1 cursor-pointer font-medium'}>{item}</SelectItem>
              ))}
            </SelectContent>
          </Select>
          <input
            type="text"
            value={inputText}
            onChange={handleInputChange}
            placeholder="Введите название позиции"
            className={'w-[320px] h-[42px] text-[16px] p-2 rounded-[12px] border-[#737984] border-[1px]'}
          />
        </div>
      </div>
      <Table className={'border-[#E0E0E0] border-2 rounded-[12px] overflow-hidden text-[16px]'}>
        <TableHeader className={'bg-[#6683DE]'}>
          <TableRow className={'text-center'}>
            <TableHead className={"rounded-tl-[12px] w-[100px] text-white"}>Позиция</TableHead>
            <TableHead className={'text-white'}>Департамент</TableHead>
            <TableHead className={'text-white'}>Руководитель</TableHead>
            <TableHead className={'rounded-tr-[12px] text-white'}>Приказ</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody className={'bg-white'}>
          {displayedVacancies.map((vacancy) => (
            <>
              <TableRow
                className={'border-[#E0E0E0] text-main-dark-gray'}>
                <TableCell className={'w-[300px] py-2 px-4'}>{vacancy.position}</TableCell>
                <TableCell className={'py-2 px-4'}>{vacancy.department}</TableCell>
                <TableCell className={'py-2 px-4'}>{vacancy.manager}</TableCell>
                <TableCell className={'py-2 px-4'}>{vacancy.action}</TableCell>
              </TableRow>
            </>
          ))}
        </TableBody>
      </Table>
      <hr/>
    </div>
  )
}
