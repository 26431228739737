import {Table, TableBody, TableCell, TableRow} from "../../components/ui/table";
import Assets from "../../components/Assets/Assets";
import React, {useState} from "react";
import EmployeesTable from "../../components/EmployeesTable/EmployeesTable";
import {Select, SelectContent, SelectItem, SelectTrigger, SelectValue} from "../../components/ui/select";
import Application from "../../components/Applications/Application";
import {ChatAssistAi} from "../../components/ChatAssistAi";
import {AIType} from "../../types/chat.types";


const events = [
  {
    id: 1,
    title: "Собрание отдела маркетинг по теме смены принципов управления процессами",
    due: "Через 3 дня"
  },
  {
    id: 2,
    title: "Презентация последних разработок по новому продукту",
    due: "Через 6 дней"
  },
  {
    id: 3,
    title: "Мероприятие в честь дня Независимости",
    due: "Через 2 недели"
  }
];


const Dashboard = () => {
  const [status, setStatus] = useState('');
  const [inputText, setInputText] = useState('');

  const handleChange = (value: string) => {
    if (value.trim() === "") {
      setStatus("");
    } else {
      setStatus(value);
    }
  };
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputText(event.target.value);
  };

  return (
    <div className={'text-black'}>
      <div className={'flex flex-col gap-8'}>
        {/*----------Заявки----------*/}
        <div className={'flex gap-6 flex-row-reverse flex-wrap transition-all '}>
          <ChatAssistAi module={AIType.CALL_CENTRE}/>
          <Application/>
        </div>

        {/*----------Сотрудники----------*/}
        <div>
          <EmployeesTable limit={5}/>
        </div>
        {/*----------Предстоящие события----------*/}
        <div>
          <h2 className={'text-[20px] text-main-dark-gray font-medium mb-6'}>Предстоящие события</h2>
          <div className={'flex flex-col w-full'}>
            <Table className={'border border-red-700 rounded-lg overflow-hidden bg-white text-[16px]'}>
              <TableBody className={'border-red-700 border'}>
                {events.map((item) => (
                  <TableRow key={item.id}>
                    <TableCell className={'flex flex-col px-6 py-4'}>
                      <h2 className={'font-medium text-main-dark-gray'}>{item.title}</h2>
                      <p className={'text-[#737984]'}>{item.due}</p>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </div>
        {/*----------Активы----------*/}
        <div>
          {/*<h2 className={'text-[24px] text-[#333333] mb-6'}>Активы</h2>*/}
          {/*<Assets limit={5}/>*/}
          <div className={'flex justify-between'}>
            <h2 className={'text-[20px] text-main-dark-gray font-medium mb-6'}>Активы</h2>
            <div className={'flex gap-4'}>
              <Select onValueChange={handleChange}>
                <SelectTrigger className="w-[140px] rounded-[12px] h-[42px] text-[12px] border-[#737984] border-[1px]">
                  <SelectValue placeholder="Все акты"/>
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value={" "} className={'mb-1 cursor-pointer text-main-dark-gray font-medium text-[12px]'}>Все акты</SelectItem>
                  <SelectItem value="Перемещение" className={'mb-1 cursor-pointer font-medium text-[12px]'}
                              style={{backgroundColor: "#26B35C", color: 'white'}}>Перемещение</SelectItem>
                  <SelectItem value="Поломка" className={'mb-1 cursor-pointer font-medium text-[12px]'}
                              style={{backgroundColor: "#E8A23A", color: 'white'}}>Поломка</SelectItem>
                  <SelectItem value="Ремонт" className={'mb-1 cursor-pointer font-medium text-[12px]'}
                              style={{backgroundColor: "rgba(14,120,241,0.7)", color: 'white'}}>Ремонт</SelectItem>
                  <SelectItem value="Списан" className={'mb-1 cursor-pointer font-medium text-[12px]'}
                              style={{backgroundColor: "#CE4667", color: 'white'}}>Списан</SelectItem>
                </SelectContent>
              </Select>
              <input
                type="text"
                value={inputText}
                onChange={handleInputChange}
                placeholder="Введите имя сотрудника"
                className={'w-[240px] h-[42px] text-[12px] py-2 px-4 rounded-[12px] border-[#737984] border-[1px]'}
              />
            </div>
          </div>
          <Assets statusFilter={status} nameFilter={inputText}/>
        </div>
      </div>
    </div>
  );
};
export default Dashboard;
